<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Invoice
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-menu offset-y left>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :disabled="pageLoading"
                  class="mr-4 custom-bold-button"
                  color="cyan white--text"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                >
                  {{ entity.name }}
                </v-btn>
              </template>
              <span>Entity</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in entityList"
              :key="index"
              link
              v-on:click="updateEntity(item)"
            >
              <v-list-item-title class="font-weight-600">{{
                item.name
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice #
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="4" class="pb-4">
              <label>Prefix</label>
              <v-text-field
                v-model.trim="setting.prefix"
                dense
                filled
                label="Prefix"
                solo
                flat
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Next Number</label>
              <v-text-field
                v-model.trim="setting.next_number"
                dense
                filled
                label="Next Number"
                solo
                flat
                type="number"
                min="0"
                max="999"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-4">
              <label>Number Length</label>
              <v-text-field
                v-model.trim="setting.number_length"
                dense
                filled
                label="Number Length"
                solo
                flat
                type="number"
                min="0"
                max="15"
                hide-details
                :disabled="pageLoading"
                :loading="pageLoading"
                color="cyan"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice Terms &amp; Conditions
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="pb-4">
              <editor
                v-model="setting.terms_conditions"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card flat class="custom-grey-border remove-border-radius mt-4">
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                Invoice Internal Notes
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateInvoiceSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="6" class="pb-4">
              <editor
                v-model="setting.admin_note"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col>
            <v-col md="6" class="pb-4">
              <editor
                v-model="setting.client_note"
                :disabled="pageLoading"
                :loading="pageLoading"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { QUERY, PATCH } from "@/core/services/store/request.module";
import { getConfig } from "@/core/services/local.service";
import TinyMCE from "@/view/pages/partials/TinyMCE.vue";

export default {
  name: "profile-invoice-setting",
  data() {
    return {
      pageLoading: true,
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        terms_conditions: null,
        admin_note: null,
        client_note: null,
        entity: 1,
      },
      entityList: [],
      entity: {},
    };
  },
  components: {
    editor: TinyMCE,
  },
  methods: {
    getInvoiceSetting() {
      const _this = this;
      _this.$store
        .dispatch(QUERY, { 
          url: "setting/invoice",
          data: { entity: this.entity.id },
         })
        .then(({ data }) => {
          _this.setting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateInvoiceSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(PATCH, { url: "setting/invoice", data: _this.setting })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    updateEntity(row) {
      this.entity = row;
      this.setting.entity = row.id;
      this.pageLoading = true;
      this.getInvoiceSetting();
    },
  },
  mounted() {
    this.getInvoiceSetting();
  },
  beforeMount() {
    this.entityList = getConfig("entity");

    this.entityList = this.entityList.map( (row) => {
				return row;
			}).filter((row) => row.id != 0);

    /* this.$nextTick(() => { */
      this.entity = this.lodash.find(this.entityList, { id: 1 });
   /*  }); */
    
  },
};
</script>
