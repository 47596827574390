<template>
  <v-text-field
    ref="search"
    v-model.trim="searchModel"
    :background-color="!isFocused ? 'grey lighten-3' : undefined"
    :flat="!isFocused && !isSearching"
    :placeholder="placeholder"
    autocomplete="off"
    class="mx-2 mx-md-4 rounded-lg"
    dense
    hide-details
    solo
    style="max-width: 450px; min-width: 400px"
    @blur="resetSearch"
    @focus="isFocused = true"
    @keydown.esc="onEsc"
    @keydown.enter="onEnter"
    @keydown.tab="onTab"
  >
    <template #prepend-inner>
      <v-icon :color="!isFocused ? 'grey' : undefined" class="ml-1 mr-2">
        mdi-magnify
      </v-icon>
    </template>
  </v-text-field>
</template>

<script>
import { SearchEventBus } from "@/core/lib/search.lib";

export default {
  data: () => ({
    isFocused: false,
    searchModel: null,
  }),
  props: {
    searchTitle: {
      type: String,
      default: "",
    },
  },
  methods: {
    onEsc() {
      this.resetSearch();
    },
    onEnter() {
      this.startSearch();
    },
    onTab() {
      this.startSearch();
    },
    startSearch() {
      SearchEventBus.$emit("start-search", this.searchModel);
    },
    resetSearch() {
      const _this = this;
      _this.$nextTick(() => {
        _this.searchModel = null;
        _this.isFocused = false;
      });
    },
  },
  beforeDestroy() {
    document.onkeydown = null;
    this.resetSearch();
    // SearchEventBus.$off("search-template");
  },
  beforeCreate() {
    const _this = this;
    SearchEventBus.$on("search-template", (argument) => {
      if (!argument) {
        _this.resetSearch();
      }
    });
  },
  mounted() {
    const _this = this;
    if (
      _this.lodash.isEmpty(_this.$route.query) === false &&
      _this.$route.query.search
    ) {
      _this.searchModel = _this.$route.query.search;
      _this.startSearch();
      _this.$refs.search.focus();
    }
  },
  computed: {
    placeholder() {
      return this.isFocused
        ? "Start Typing then press enter"
        : "Search " +
            (this.searchTitle === "Engineer" ? "Staffs" : this.searchTitle) +
            "...";
    },
    isSearching() {
      return this.searchModel && this.searchModel.length > 0;
    },
  },
};
</script>
