<template>
  <v-container
    class="product-create create-form white-background main-create-page"
  >
    <v-layout>
      <v-flex md6>
        <h1 class="form-title d-flex">
          <template v-if="service_id">Update</template
          ><template v-else>Create</template> Service
        </h1>
      </v-flex>
      <v-flex md6 class="text-right">
        <v-btn
          :disabled="!form_valid || form_loading"
          :loading="form_loading"
          class="mx-2 custom-bold-button text-white"
          color="cyan"
          v-on:click="update_or_create()"
        >
          <template v-if="service_id">Update</template
          ><template v-else>Create</template> Service
        </v-btn>
        <v-btn
          v-on:click="goBack"
          :disabled="form_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          outlined
          >Cancel
        </v-btn>
      </v-flex>
    </v-layout>
    <v-container style="overflow-y: auto; min-height: calc(100vh - 110px)">
      <v-row class="mx-0 product-create-form">
        <v-col md="9" sm="12">
          <v-form
            ref="product_form"
            v-model.trim="form_valid"
            lazy-validation
            v-on:submit.stop.prevent="updateOrCreate"
          >
            <table width="100%">
              <tr>
                <td valign="middle" width="15%">
                  <label class="font-weight-700 required font-size-16 width-100"
                    >Name</label
                  >
                </td>
                <td valign="middle" colspan="3">
                  <v-text-field
                    dense
                    filled
                    label="Name"
                    solo
                    flat
                    class="width-100"
                    color="cyan"
                    v-model.trim="service_create.name"
                    :rules="[
                      validateRules.required(service_create.name, 'Name'),
                      validateRules.minLength(service_create.name, 'Name', 1),
                      validateRules.maxLength(service_create.name, 'Name', 100),
                    ]"
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td valign="top">
                  <label class="pt-2 font-weight-700 font-size-16 width-100"
                    >Description</label
                  >
                </td>
                <td colspan="3" valign="middle">
                  <v-textarea
                    dense
                    filled
                    label="Description"
                    solo
                    flat
                    row-height="20"
                    auto-grow
                    color="cyan"
                    v-model.trim="service_create.description"
                    :rules="[
                      validateRules.minLength(
                        service_create.description,
                        'Description',
                        1
                      ),
                      validateRules.maxLength(
                        service_create.description,
                        'Description',
                        1024
                      ),
                    ]"
                  ></v-textarea>
                </td>
              </tr>
              <tr>
                <td valign="middle" width="15%">
                  <label class="font-weight-700 font-size-16 width-100"
                    >Reference</label
                  >
                </td>
                <td valign="middle" width="35%">
                  <v-text-field
                    dense
                    filled
                    label="Reference"
                    solo
                    flat
                    class="width-100"
                    color="cyan"
                    v-model.trim="service_create.reference"
                    :rules="[
                      validateRules.minLength(
                        service_create.reference,
                        'Reference',
                        1
                      ),
                      validateRules.maxLength(
                        service_create.reference,
                        'Reference',
                        100
                      ),
                    ]"
                    hide-details
                  ></v-text-field>
                </td>
                <td valign="middle" width="15%">
                  <label class="font-weight-700 required font-size-16 width-100"
                    >Service #</label
                  >
                </td>
                <td valign="middle" width="35%">
                  <v-text-field
                    dense
                    filled
                    label="Service #"
                    solo
                    flat
                    class="width-100"
                    color="cyan"
                    readonly
                    v-model.trim="service_create.barcode"
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td valign="middle" width="15%">
                  <label class="font-weight-700 font-size-16 width-100"
                    >Category</label
                  >
                </td>
                <td valign="middle" width="35%">
                  <v-autocomplete
                    dense
                    filled
                    color="cyan"
                    item-color="cyan"
                    :items="category_list"
                    label="Category"
                    solo
                    flat
                    hide-details
                    append-outer-icon="mdi-cog"
                    v-on:click:append-outer="manage_category_dialog = true"
                    item-text="text"
                    item-value="id"
                    class="width-100"
                    v-model.trim="service_create.category"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="'No Category Found.'"
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-autocomplete>
                </td>
                <td valign="middle" width="15%">
                  <label class="font-weight-700 font-size-16 width-100"
                    >Charges</label
                  >
                </td>
                <td valign="middle" width="35%">
                  <v-text-field
                    dense
                    filled
                    label="Charges"
                    solo
                    flat
                    class="width-100"
                    color="cyan"
                    v-model.trim="service_create.charges"
                    hide-details
                    type="number"
                    min="0"
                    max="9999"
                    v-on:keypress="limitDecimal($event, service_create.charges)"
                  ></v-text-field>
                </td>
              </tr>
              <tr>
                <td colspan="4" valign="middle">
                  <label class="font-weight-700 font-size-18 width-100"
                    >Images
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon small class="mx-2" v-bind="attrs" v-on="on"
                          >mdi-progress-question</v-icon
                        >
                      </template>
                      <span>More than 5 images are not allowed</span>
                    </v-tooltip>
                  </label>
                </td>
              </tr>
              <tr>
                <td colspan="4" valign="middle">
                  <div>
                    <FileTemplate
                      allow-upload
                      is-min-display
                      :attachments="service_create.product_images"
                      v-on:file:updated="update_images"
                    ></FileTemplate>
                  </div>
                </td>
              </tr>
            </table>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <ManageBrand
      :dialog="manage_brand_dialog"
      :brand="brand_list"
      v-on:close-dialog="manage_brand_dialog = false"
      v-on:get-product-brand="get_options()"
    ></ManageBrand>
    <ManageManufacturer
      :dialog="manage_manufacturer_dialog"
      :manufacturer="manufacturer_list"
      v-on:close-dialog="manage_manufacturer_dialog = false"
      v-on:get-product-manufacturer="get_options()"
    ></ManageManufacturer>
    <ManageCategory
      product
      :dialog="manage_category_dialog"
      :category="category_list"
      v-on:close-dialog="manage_category_dialog = false"
      v-on:get-product-category="get_options()"
    ></ManageCategory>
    <ManageUnit
      :dialog="manage_unit_dialog"
      :unit="unit_list"
      v-on:close-dialog="manage_unit_dialog = false"
      v-on:get-product-unit="get_options()"
    ></ManageUnit>
  </v-container>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import FileTemplate from "@/view/pages/partials/FileTemplate.vue";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageManufacturer from "@/view/pages/partials/Manage-Product-Manufacturer.vue";
import ManageCategory from "@/view/pages/partials/Manage-Product-Category.vue";
import ManageUnit from "@/view/pages/partials/Manage-Product-Unit.vue";
import {
  POST,
  GET,
  PUT,
  CLEAR_ERROR,
  UPDATE_ERROR,
} from "@/core/services/store/request.module";
import ObjectPath from "object-path";

export default {
  mixins: [ValidationMixin],
  name: "create-product",
  data() {
    return {
      service_id: 0,
      form_valid: true,
      form_loading: false,
      manage_unit_dialog: false,
      manage_manufacturer_dialog: false,
      manage_brand_dialog: false,
      manage_category_dialog: false,
      unit_list: [],
      manufacturer_list: [],
      brand_list: [],
      category_list: [],
      service_create: {
        product: null,
        name: null,
        reference: null,
        serial_number: null,
        description: null,
        part_number: null,
        barcode: null,
        reorder_level: null,
        unit: null,
        manufacturer: null,
        brand: null,
        category: null,
        initial_stock: null,
        purchase_cost: null,
        selling_cost: null,
        weight: null,
        length: null,
        width: null,
        height: null,
        manage_stock: true,
        track_batch: false,
        accounting: "none",
        product_type: "service",
        taxable: false,
        sellable: true,
        confirmed: true,
        images: [],
        product_images: [],
      },
    };
  },
  methods: {
    update_or_create() {
      const _this = this;

      if (!_this.$refs.product_form.validate()) {
        return false;
      }

      _this.form_loading = true;
      _this.$store.dispatch(CLEAR_ERROR, {});

      let requestType = POST;
      let requestURL = "product";

      if (_this.service_id && _this.service_id > 0) {
        requestType = PUT;
        requestURL = "product/" + _this.service_id;
      }

      _this.$store
        .dispatch(requestType, { url: requestURL, data: _this.service_create })
        .then((response) => {
          _this.service_id = ObjectPath.get(response, "data.id", 0);

          _this.$router.push(
            _this.getDefaultRoute("service", {
              query: {
                status: "all",
              },
            })
          );
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.form_loading = false;
        });
    },
    load_content() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: `product/${_this.service_id}` })
        .then((response) => {
          const product = ObjectPath.get(response, "data", {});

          _this.service_create.product = product.product; //: null,
          _this.service_create.reference = product.reference; //: null,
          _this.service_create.name = product.name; //: null,
          _this.service_create.serial_number = product.serial_number; //: null,
          _this.service_create.description = product.description; //: null,
          _this.service_create.part_number = product.part_number; //: null,
          _this.service_create.barcode = product.barcode; //: null,
          _this.service_create.reorder_level = product.reorder_level; //: null,
          _this.service_create.category = ObjectPath.get(
            product,
            "category.id",
            0
          ); //: null,
          _this.service_create.initial_stock = product.initial_stock; //: null,
          _this.service_create.purchase_cost = product.purchase_cost; //: null,
          _this.service_create.selling_cost = product.selling_cost; //: null,
          _this.service_create.charges = product.charges; //: null,
          _this.service_create.weight = product.weight; //: null,
          _this.service_create.length = product.length; //: null,
          _this.service_create.width = product.width; //: null,
          _this.service_create.height = product.height; //: null,
          _this.service_create.product_images = product.product_images; //: [],

          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Service", route: "service" },
            { title: product.barcode },
            { title: "Update" },
          ]);
        })
        .catch((error) => {
          _this.$store.dispatch(UPDATE_ERROR, error);
        });
    },
    update_images(param) {
      this.service_create.images = param;
    },
    get_options() {
      this.$store
        .dispatch(GET, { url: `product/options?product_type=service` })
        .then((response) => {
          const output = ObjectPath.get(response, "data", {});
          this.service_create.barcode = output.barcode;
          if (!this.service_create.serial_number) {
            this.service_create.serial_number = output.serial_number;
          }
          this.brand_list = output.brand;
          this.manufacturer_list = output.manufacturer;
          this.unit_list = output.unit;
          this.category_list = output.category;
        })
        .catch((error) => {
          this.$store.dispatch(UPDATE_ERROR, error);
        })
        .finally(() => {
          if (this.service_id > 0) {
            this.load_content();
          }
        });
    },
  },
  components: {
    FileTemplate,
    ManageBrand,
    ManageManufacturer,
    ManageCategory,
    ManageUnit,
  },
  created() {
    this.service_id = this.lodash.toSafeInteger(this.$route.params.id);
  },
  mounted() {
    this.get_options();

    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Service", route: "service" },
      { title: "Create" },
    ]);
  },
};
</script>
