<template>
  <Dialog :commonDialog="personDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <span v-if="doUpdate">Update</span>
      <span v-else>Add</span>
      <span v-if="isTenant">&nbsp;Tenant</span
      ><span v-else>&nbsp;Contact Person</span>
    </template>
    <template v-slot:body>
      <v-form
        ref="personForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="appendContactPerson"
      >
        <v-row class="mx-3">
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="y-salutation"
              >Salutation</label
            >
            <v-select
              id="y-salutation"
              v-model.trim="contactPerson.title"
              :items="salutationList"
              dense
              filled
              :rules="[
                validateRules.required(contactPerson.title, 'salutation'),
              ]"
              item-text="text"
              item-value="value"
              item-color="cyan"
              label="Salutation"
              solo
              flat
              color="cyan"
            ></v-select>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="y-first_name"
              >First Name</label
            >
            <v-text-field
              id="y-first_name"
              v-model.trim="contactPerson.first_name"
              :rules="[
                validateRules.required(contactPerson.first_name, 'first name'),
                validateRules.minLength(
                  contactPerson.first_name,
                  'first name',
                  2
                ),
                validateRules.maxLength(
                  contactPerson.first_name,
                  'first name',
                  100
                ),
              ]"
              dense
              filled
              label="First Name"
              solo
              flat
              color="cyan"
              class="required-field"
              v-on:keyup="appendDisplayName()"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label class="font-size-16 font-weight-500" for="y-last_name"
              >Last Name</label
            >
            <v-text-field
              id="y-last_name"
              v-model.trim="contactPerson.last_name"
              :rules="[
                validateRules.minLength(
                  contactPerson.last_name,
                  'last name',
                  2
                ),
                validateRules.maxLength(
                  contactPerson.last_name,
                  'last name',
                  100
                ),
              ]"
              dense
              filled
              label="Last Name"
              solo
              flat
              color="cyan"
              v-on:keyup="appendDisplayName()"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="y-display_name"
              >Display Name</label
            >
            <v-text-field
              id="y-display_name"
              v-model.trim="contactPerson.display_name"
              :rules="[
                validateRules.required(
                  contactPerson.display_name,
                  'display name'
                ),
                validateRules.minLength(
                  contactPerson.display_name,
                  'display name',
                  2
                ),
                validateRules.maxLength(
                  contactPerson.display_name,
                  'display name',
                  100
                ),
              ]"
              dense
              filled
              label="Display Name"
              solo
              flat
              color="cyan"
              class="required-field"
            ></v-text-field>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="y-primary_phone"
              >Phone Number</label
            >
            <PhoneTextField
              id="y-primary_phone"
              v-model="contactPerson.primary_phone"
              required
            >
            </PhoneTextField>
          </v-col>
          <v-col md="4">
            <label
              class="font-size-16 font-weight-500 required"
              for="y-primary_email"
              >Email</label
            >
            <v-text-field
              id="y-primary_email"
              v-model.trim="contactPerson.primary_email"
              :rules="[
                validateRules.required(contactPerson.primary_email, 'email'),
                validateRules.validEmail(contactPerson.primary_email, 'email'),
                validateRules.minLength(
                  contactPerson.primary_email,
                  'email',
                  2
                ),
                validateRules.maxLength(
                  contactPerson.primary_email,
                  'email',
                  100
                ),
              ]"
              dense
              filled
              label="Email"
              solo
              flat
              color="cyan"
              class="required-field"
            ></v-text-field>
            <v-combobox
              v-if="false"
              small-chips
              id="y-primary_email"
              :items="contactPerson.emails"
              v-model="contactPerson.emails"
              label="Emails"
              color="cyan"
              multiple
              dense
              filled
              solo
              flat
              item-color="cyan"
              v-on:change="validateTagEmail()"
              hide-details
              :rules="[validateRules.required(contactPerson.emails, 'emails')]"
            >
              <template v-slot:selection="{ attrs, item, index, selected }">
                <v-chip
                  label
                  small
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click:close="removeEmail(item)"
                  v-if="index === 0"
                >
                  <span>{{ item }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text font-size-12 mx-2">
                  (+{{ contactPerson.emails.length - 1 }} others)
                </span>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        v-on:click="$emit('close', true)"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        class="mx-2 custom-bold-button white--text"
        v-on:click="appendContactPerson"
        color="cyan"
        :disabled="!formValid"
      >
        <span v-if="doUpdate">Update</span>

        <span v-else>Add</span>
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { SET_CC_PERSONS } from "@/core/services/store/common.module";
import { PUT } from "@/core/services/store/request.module";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [ValidationMixin, CommonMixin],
  data() {
    return {
      contactPersons: [],
      emails: [],
      contactPerson: {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        will_notified: 1,
        position: null,
        default: 0,
      },
    };
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    personDialog: {
      type: Boolean,
      default: false,
    },
    isTenant: {
      type: Boolean,
      default: false,
    },
    doSave: {
      type: Boolean,
      default: false,
    },
    doUpdate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    personId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initComponent();
      },
    },
    personDialog() {
      this.initComponent();
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      },
    },
  },
  methods: {
    validateTagEmail() {
      for (let i = 0; i < this.contactPerson.emails.length; i++) {
        if (
          this.validateRules.validEmail(this.contactPerson.emails[i]) !== true
        ) {
          this.contactPerson.emails.splice(i, 1);
        }
      }
    },
    removeEmail(email) {
      const index = this.lodash.findIndex(
        this.contactPerson.emails,
        (row) => email == row
      );
      if (index >= 0) {
        this.contactPerson.emails.splice(index, 1);
      }
    },
    initComponent() {
      const result = this.lodash.cloneDeep(this.value);
      this.contactPersons = result;

      if (this.doUpdate) {
        this.$nextTick(() => {
          const contact_person = this.lodash.find(this.contactPersons, {
            id: this.personId,
          });

          if (contact_person) {
            this.$nextTick(() => {
              this.contactPerson = {
                id: contact_person.id,
                title: contact_person.title,
                first_name: contact_person.first_name,
                last_name: contact_person.last_name,
                display_name: contact_person.display_name,
                primary_email: contact_person.primary_email,
                emails: contact_person.emails,
                primary_phone: contact_person.primary_phone,
                will_notified: +contact_person.will_notified,
                position: contact_person.position,
                default: +contact_person.default,
              };
            });
          }
        });
      } else {
        this.$nextTick(() => {
          this.contactPerson = {
            id: null,
            title: "mr",
            first_name: null,
            last_name: null,
            display_name: null,
            primary_email: null,
            emails: [],
            primary_phone: null,
            will_notified: 1,
            position: null,
            default: 0,
          };
          this.$nextTick(() => {
            this.contactPerson.primary_phone = null;
          });
        });
      }
    },
    appendDisplayName() {
      if (!this.doUpdate) {
        const result = [];
        const { first_name, last_name } = this.contactPerson;
        if (first_name) {
          result.push(first_name);
        }
        if (last_name) {
          result.push(last_name);
        }
        this.contactPerson.display_name = result.join(" ");
      }
    },

    async appendContactPerson() {
      if (!this.$refs.personForm.validate()) {
        return false;
      }

      let primary_email = this.contactPerson.primary_email;
      let contactId = this.contactPerson.id;
      var emailsObj = this.contactPersons.filter(function (contactpersn) {
        if (
          contactpersn.primary_email == primary_email &&
          (contactpersn.id != contactId || contactId == null)
        ) {
          return contactpersn.primary_email;
        }
      });

      if (emailsObj.length > 0) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError(`Email ${primary_email} already is used`)
        );
        return false;
      }

      this.contactPersons.push({
        menu: false,
        id: this.contactPerson.id,
        title: this.contactPerson.title,
        first_name: this.contactPerson.first_name,
        last_name: this.contactPerson.last_name,
        display_name: this.contactPerson.display_name,
        primary_email: this.contactPerson.primary_email,
        primary_phone: this.contactPerson.primary_phone,
        emails: this.contactPerson.emails,
        will_notified: this.contactPerson.will_notified,
        position: this.contactPerson.position,
        default: this.contactPerson.default,
      });

      if (this.doSave || this.doUpdate) {
        this.contactPerson.tenant = this.lodash.toSafeInteger(this.isTenant);
        try {
          const uuid = uuidv4();

          this.contactPerson.uuid = uuid;

          const { data } = await this.$store.dispatch(PUT, {
            url: `customer/${this.customerId}/contact-person`,
            data: { persons: [this.contactPerson] },
          });

          this.contactPerson = this.lodash.find(data, { uuid });

          this.contactPersons = data;
        } catch (error) {
          this.logError(error);
          return false;
        }
      }

      this.$emit("input", this.contactPersons);

      this.$emit("update:person", this.contactPerson);

      if (this.isTenant) {
        const tenant = this.lodash.cloneDeep(this.contactPerson);

        this.$emit("update:tenant", tenant);
      }

      this.$store.dispatch(SET_CC_PERSONS, this.contactPersons);

      this.$nextTick(() => {
        this.contactPerson = {
          id: null,
          title: "mr",
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          emails: [],
          will_notified: 1,
          position: null,
          default: 0,
        };
      });

      this.$emit("close", true);
    },
  },
  components: {
    Dialog,
    PhoneTextField,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  mounted() {
    this.initComponent();
  },
};
</script>
