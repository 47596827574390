<template>
  <CreateUpdateTemplate
    :routePreventDialog="routePreventDialog"
    :customClass="'quotation-create'"
    v-if="getPermission('quotation:create')"
  >
    <template v-slot:header-title>
      <h1 class="form-title">
        <v-layout>
          <v-flex class="flex-grow-0"> Credit Note For </v-flex>
          <v-flex
            class="m-0 form-title-create-link pl-2"
            link
            :class="{ 'text-truncate': !lodash.isEmpty(customer) }"
          >
            <template v-if="lodash.isEmpty(customer)">Client Name</template>
            <template v-else>{{ customer.display_name }}</template>
            <v-icon
              link
              large
              color="cyan"
              class="mx-2"
              v-if="lodash.isEmpty(customer)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-flex>
        </v-layout>
      </h1>
    </template>
    <template v-slot:header-action>
      <v-btn
        v-on:click="goBack()"
        :disabled="formLoading || page_loading"
        class="mx-2 custom-grey-border custom-bold-button"
        depressed
      >
        Cancel
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || page_loading"
        :loading="formLoading"
        class="mx-2 custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate()"
      >
        Save Credit Note
      </v-btn>
    </template>
    <template v-slot:body>
      <v-form
        ref="creditForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="updateOrCreate()"
      >
        <div class="credit-note-create-page mx-4">
          <v-row>
            <v-col md="7">
              <label
                class="font-weight-600 font-size-18 px-2 required"
                for="credit-note-title"
                >Credit Note Title</label
              >
              <v-text-field
                v-model.trim="credit_create.title"
                dense
                id="credit-note-title"
                filled
                label="Title"
                solo
                flat
                :disabled="page_loading"
                :loading="page_loading"
                color="cyan"
                :rules="[
                  validateRules.required(
                    credit_create.title,
                    'Credit Note Title'
                  ),
                  validateRules.minLength(
                    credit_create.title,
                    'Credit Note Title',
                    1
                  ),
                  validateRules.maxLength(
                    credit_create.title,
                    'Credit Note Title',
                    100
                  ),
                ]"
              ></v-text-field>
              <v-textarea
                v-model.trim="credit_create.description"
                auto-grow
                dense
                filled
                :disabled="page_loading"
                :loading="page_loading"
                color="cyan"
                label="Description"
                solo
                flat
                :rules="[
                  validateRules.minLength(
                    credit_create.description,
                    'Description',
                    1
                  ),
                  validateRules.maxLength(
                    credit_create.description,
                    'Description',
                    1024
                  ),
                ]"
                row-height="25"
              ></v-textarea>
            </v-col>
            <v-col cols="5" class="pb-0">
              <table width="100%">
                <tr>
                  <td width="150" class="font-weight-600">
                    <label>Credit Note #</label>
                  </td>
                  <td class="font-weight-700">
                    <label>{{ credit_create.barcode }}</label>
                  </td>
                </tr>
                <tr>
                  <td width="150" class="font-weight-600">
                    <label for="reference-no">Reference #</label>
                  </td>
                  <td>
                    <v-text-field
                      id="reference-no"
                      v-model.trim="credit_create.reference"
                      dense
                      filled
                      :disabled="page_loading"
                      :loading="page_loading"
                      color="cyan"
                      label="Reference #"
                      :rules="[
                        validateRules.minLength(
                          credit_create.reference,
                          'Reference',
                          1
                        ),
                        validateRules.maxLength(
                          credit_create.reference,
                          'Reference',
                          100
                        ),
                      ]"
                      solo
                      flat
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <td width="150" class="font-weight-600">
                    <label for="start-date-picker">Credit Note Date</label>
                  </td>
                  <td>
                    <DatePicker
                      solo
                      flat
                      placeholder="Credit Note Date"
                      :default-date="credit_create.credit_note_date"
                      v-model="credit_create.credit_note_date"
                    ></DatePicker>
                  </td>
                </tr>
                <tr>
                  <td width="150" class="font-weight-600">
                    <label for="credit-note-reason">Credit Note Reason</label>
                  </td>
                  <td>
                    <v-text-field
                      id="credit-note-reason"
                      v-model.trim="credit_create.reason"
                      dense
                      filled
                      :disabled="page_loading"
                      :loading="page_loading"
                      color="cyan"
                      label="Credit Note Reason"
                      :rules="[
                        validateRules.minLength(
                          credit_create.reason,
                          'Credit Note Reason',
                          1
                        ),
                        validateRules.maxLength(
                          credit_create.reason,
                          'Credit Note Reason',
                          100
                        ),
                      ]"
                      solo
                      flat
                    ></v-text-field>
                  </td>
                </tr>
              </table>
            </v-col>
            <v-col cols="12" class="mx-4">
              <v-layout class="my-4">
                <v-flex md4 class="mr-2 custom-border-right">
                  <table width="100%" class="compressed-rows">
                    <tr>
                      <td class="font-weight-600">
                        <label>Service Location</label>
                        <v-icon
                          v-if="!creditId"
                          class="ml-3"
                          v-on:click="customer_property_dialog = true"
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <label>{{ property.property_address }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">
                        <label>Contact details</label>
                        <v-chip
                          v-if="property_contact_person.tenant"
                          small
                          color="green white--text"
                          label
                          class="ml-2 tenant-small-chip"
                          >Tenant</v-chip
                        >
                        <v-icon
                          v-if="!creditId"
                          class="ml-3"
                          v-on:click="
                            person_type = 'property';
                            customer_person_dialog = true;
                          "
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        >
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.display_name">
                      <td class="py-0">
                        <span>{{ property_contact_person.display_name }}</span>
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_phone">
                      <td class="py-0">
                        <span>{{ property_contact_person.primary_phone }}</span>
                      </td>
                    </tr>
                    <tr v-if="property_contact_person.primary_email">
                      <td class="py-0">
                        <span>{{ property_contact_person.primary_email }}</span>
                      </td>
                    </tr>
                    <tr v-if="!creditId">
                      <td>
                        <v-layout class="mt-2">
                          <v-flex
                            ><label class="font-weight-600">
                              Email Notification
                            </label></v-flex
                          >
                          <v-flex class="d-flex justify-end align-center">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-switch
                                  class="m-0 p-0"
                                  color="cyan"
                                  dense
                                  v-model="credit_create.property_person_notify"
                                  inset
                                ></v-switch>
                              </template>
                              <span
                                v-if="credit_create.property_person_notify == 1"
                                >Send Notification</span
                              >
                              <span v-else>No Notification</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md4 class="ml-2 mr-2 custom-border-right">
                  <table width="100%" class="compressed-rows">
                    <tr>
                      <td class="font-weight-600">
                        <label>Billing Location</label>
                        <v-icon
                          v-if="!creditId"
                          class="ml-3"
                          v-on:click="customer_billing_dialog = true"
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        >
                      </td>
                    </tr>
                    <tr>
                      <td class="py-0">
                        <label>{{ billing.property_address }}</label>
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-600">
                        <label>Contact details</label>
                        <v-chip
                          v-if="billing_contact_person.tenant"
                          small
                          color="green white--text"
                          label
                          class="ml-2 tenant-small-chip"
                          >Tenant</v-chip
                        >
                        <v-icon
                          v-if="!creditId"
                          class="ml-3"
                          v-on:click="
                            person_type = 'billing';
                            customerPersonDialog = true;
                          "
                          color="cyan"
                          small
                          >mdi-pencil</v-icon
                        >
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.display_name">
                      <td class="py-0">
                        <span>{{ billing_contact_person.display_name }}</span>
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_phone">
                      <td class="py-0">
                        <span>{{ billing_contact_person.primary_phone }}</span>
                      </td>
                    </tr>
                    <tr v-if="billing_contact_person.primary_email">
                      <td class="py-0">
                        <span>{{ billing_contact_person.primary_email }}</span>
                      </td>
                    </tr>
                    <tr v-if="!creditId">
                      <td>
                        <v-layout class="mt-2">
                          <v-flex
                            ><label class="font-weight-600">
                              Email Notification
                            </label></v-flex
                          >
                          <v-flex class="d-flex justify-end align-center">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-switch
                                  class="m-0 p-0"
                                  color="cyan"
                                  dense
                                  v-model="credit_create.billing_person_notify"
                                  inset
                                ></v-switch>
                              </template>
                              <span
                                v-if="credit_create.billing_person_notify == 1"
                                >Send Notification</span
                              >
                              <span v-else>No Notification</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </td>
                    </tr>
                  </table>
                </v-flex>
                <v-flex md4 class="ml-2">
                  <table width="100%" class="compressed-rows">
                    <tr>
                      <td colspan="2" class="font-weight-600">
                        <label>Invoice</label>
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice #</td>
                      <td>
                        <span class="font-weight-600">{{
                          invoice.barcode
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice Title</td>
                      <td>
                        <span class="font-weight-600">{{ invoice.title }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice Date</td>
                      <td>
                        <span class="font-weight-600">{{
                          formatDate(invoice.invoice_date)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Invoice Amount</td>
                      <td>
                        <span class="font-weight-600">{{
                          formatMoney(invoice.total)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Paid Amount</td>
                      <td>
                        <span class="font-weight-600 green--text">{{
                          formatMoney(invoice.paid_amount)
                        }}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>Unpaid Amount</td>
                      <td>
                        <span class="font-weight-600 red--text">{{
                          formatMoney(invoice.total - invoice.paid_amount)
                        }}</span>
                      </td>
                    </tr>
                  </table>
                </v-flex>
              </v-layout>
            </v-col>
            <v-col cols="12">
              <v-container fluid>
                <v-card flat class="custom-grey-border remove-border-radius">
                  <v-card-title class="headline grey lighten-4">
                    <h3
                      class="font-weight-700 custom-headline color-custom-blue"
                    >
                      Line Items
                    </h3>
                  </v-card-title>
                  <v-card-text ref="lineItem" class="p-6 font-size-16">
                    <v-row dense>
                      <v-col cols="12" class="pt-0 pb-0">
                        <CreditLineItemNew
                          is-credit-note
                          can-update
                          :entity-id="entityId"
                          :property-id="propertyId"
                          :db-line-items="db_line_items"
                          :db-equipments="db_equipments"
                          :discount-value="credit_create.discount_value"
                          :discount-value-type="
                            credit_create.discount_value_type
                          "
                          :apply-tax="credit_create.tax_applied"
                          :adjustment-value="credit_create.adjustment"
                          v-on:update:line-item="updateLineItem($event)"
                          v-on:update:line-item-calculation="
                            updateLineItemCalculation($event)
                          "
                        ></CreditLineItemNew>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
            <v-col cols="12">
              <CreditTermsConditions
                :update-data="update_data"
                :page-loading="page_loading"
              ></CreditTermsConditions>
            </v-col>
            <v-col cols="12">
              <CreditNotesAttachment
                :update-data="update_data"
                :page-loading="page_loading"
              ></CreditNotesAttachment>
            </v-col>
          </v-row>
        </div>
      </v-form>
    </template>
  </CreateUpdateTemplate>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateUpdateTemplate from "@/view/pages/partials/Create-Update-Template.vue";
import { PreventDialogEventBus } from "@/core/lib/prevent.reload.lib";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import CreditTermsConditions from "@/view/pages/partials/Terms-Conditions.vue";
import CreditNotesAttachment from "@/view/pages/partials/Notes-Attachment.vue";
import CreditLineItemNew from "@/view/pages/partials/Line-Item-New.vue";
import ObjectPath from "object-path";
import { QUERY, POST, PUT, GET } from "@/core/services/store/request.module";
import { TermConditionEventBus } from "@/core/lib/term.condition.lib";
import { NoteAttachmentEventBus } from "@/core/lib/note.attachment.lib";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import { isEmpty, compact, map, toSafeInteger } from "lodash";
import LineItemMixin from "@/core/lib/line-item/line.item.mixin";

export default {
  mixins: [CommonMixin, ValidationMixin, LineItemMixin],
  name: "credit-note-create",
  title: "Create Credit Note",
  data() {
    return {
      formValid: true,
      page_loading: false,
      formLoading: false,
      forcePush: false,
      credit_note_created: false,
      line_item_created: false,
      routePreventDialog: false,
      creditId: 0,
      invoiceId: 0,
      customerId: 0,
      entityId: 0,
      propertyId: 0,
      customer_property_dialog: false,
      customer_billing_dialog: false,
      customer_person_dialog: false,
      db_line_items: [],
      db_equipments: [],
      customer: {},
      invoice: {},
      credit_create: {
        title: null,
        customer: null,
        invoice: null,
        description: null,
        property_person_notify: 0,
        billing_person_notify: 0,
        barcode: null,
        reference: null,
        credit_note_date: null,
        discount_value: null,
        discount_value_type: 1,
        tax_applied: null,
        adjustment: null,
      },
      terms_condition: null,
      note_attachment: {
        documents: [],
        admin_notes: null,
        client_notes: null,
      },
      property: {},
      billing: {},
      update_data: {
        term_conditions: null,
        admin_remark: null,
        client_remark: null,
        notify_admin: 0,
        notify_customer: 0,
        notify_engineer: 0,
        documents: [],
      },
      line_item: [],
      line_item_calculation: {
        tax_applied: 0,
        discount_value: 0,
        adjustment_amount: 0,
        discount_type: 1,
        discount_value_type: 0,
      },
      property_contact_person: {},
      billing_contact_person: {},
      person_type: "property",
    };
  },
  components: {
    DatePicker,
    CreditLineItemNew,
    CreateUpdateTemplate,
    CreditTermsConditions,
    CreditNotesAttachment,
  },
  methods: {
    updateLineItem(rows) {
      this.line_item = rows;
    },
    updateLineItemCalculation(row) {
      this.line_item_calculation.tax_applied = row.apply_tax;
      this.line_item_calculation.discount_value = row.discount_value;
      this.line_item_calculation.adjustment_amount = row.adjustment;
      this.line_item_calculation.discount_type = 1;
      this.line_item_calculation.discount_value_type = row.discount_value_type;
    },
    async updateOrCreate() {
      const validateStatus = this.$refs.creditForm.validate();
      const formErrors = this.validateForm(this.$refs.creditForm);

      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      const line_items = this.line_item;

      let validateLineItem = compact(
        map(line_items, function (row) {
          return row.product_id;
        })
      );

      if (isEmpty(validateLineItem)) {
        ErrorEventBus.$emit(
          "update:error",
          InitializeError("Select product/service then try again.")
        );
        return false;
      }

      if (!validateStatus) {
        return false;
      }

      let REQUEST_TYPE = POST;
      let REQUEST_URL = "credit-note";

      if (this.creditId && this.creditId > 0) {
        REQUEST_TYPE = PUT;
        REQUEST_URL = "credit-note/" + this.creditId;
      }

      const formData = new Object({
        customer: this.customerId,
        invoice: this.invoiceId,
        title: this.credit_create.title,
        reason: this.credit_create.reason,
        description: this.credit_create.description,
        property_person_notify: +!!this.credit_create.property_person_notify,
        billing_person_notify: +!!this.credit_create.billing_person_notify,
        reference: this.credit_create.reference,
        credit_note_date: this.credit_create.credit_note_date,
        documents: this.note_attachment.documents,
        admin_remark: this.note_attachment.admin_notes,
        client_remark: this.note_attachment.client_notes,
        term_conditions: this.terms_condition,
        tax_applied: this.line_item_calculation.tax_applied,
        discount_value: this.line_item_calculation.discount_value,
        adjustment: this.line_item_calculation.adjustment_amount,
        discount_type: this.line_item_calculation.discount_type,
        discount_value_type: this.line_item_calculation.discount_value_type,
      });

      if (!this.credit_note_created) {
        try {
          const credit = await this.$store.dispatch(REQUEST_TYPE, {
            url: REQUEST_URL,
            data: formData,
          });

          if (!isEmpty(credit)) {
            this.creditId = toSafeInteger(credit.data.id);
            this.credit_note_created = true;
          }
        } catch (error) {
          this.logError(error);
          this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Credit Note is not created. Please try again.")
          );
          return false;
        }
      }

      if (
        !this.line_item_created &&
        this.credit_note_created &&
        this.creditId
      ) {
        try {
          await this.CreateLineItems({
            type: "credit-note",
            parent: this.creditId,
            formData: line_items,
          });

          this.line_item_created = true;
        } catch (error) {
          this.logError(error);
          this.formLoading = false;
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("Line items are not created. Please try again.")
          );
          return false;
        }
      }

      this.formLoading = false;

      this.$nextTick(() => {
        this.$router.push(
          this.getDefaultRoute("credit-note", {
            query: {
              status: "all",
            },
          })
        );
      });
    },
    getLineItems(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        _this.$store
          .dispatch(QUERY, {
            url: "line-item/new",
            data,
          })
          .then(({ data }) => {
            resolve(data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    initLineItems() {
      if (!this.creditId) {
        this.getLineItems({ invoice: this.invoiceId })
          .then((data) => {
            this.db_line_items = [];
            for (let i = 0; i < data.length; i++) {
              this.db_line_items.push({
                id: null,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }
          })
          .catch((error) => {
            this.logError(error);
          });
      }
    },
    get_line_items(data) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "line-item/new",
          data,
        })
        .then(({ data }) => {
          if (data.length) {
            const result = [];

            for (let i = 0; i < data.length; i++) {
              result.push({
                id: data[i].id,
                group: data[i].group,
                group_primary: data[i].group_primary,
                product: data[i].product,
                product_id: data[i].product_id,
                serial_no: data[i].serial_no,
                eq_model: data[i].eq_model,
                location: data[i].location,
                product_type: data[i].product_type,
                has_warranty: data[i].has_warranty,
                warranty: data[i].warranty,
                description: data[i].description,
                rate: data[i].rate,
                quantity: data[i].quantity,
                uom: data[i].uom,
                total: data[i].total,
                visit_barcode: data[i].visit_barcode,
              });
            }

            _this.$nextTick(() => {
              _this.db_line_items = _this.lodash.filter(result, function (row) {
                return (
                  row.product_type == "goods" || row.product_type == "service"
                );
              });
              _this.db_equipments = _this.lodash.filter(result, {
                product_type: "equipment",
              });
            });
          }
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    loadContent() {
      const route_name = this.$route.name;
      if (route_name != "admin.credit-note.update" || !this.creditId) {
        return false;
      }
      this.$store
        .dispatch(GET, { url: "credit-note/" + this.creditId })
        .then((response) => {
          this.credit_create.title = ObjectPath.get(response, "data.title");
          this.credit_create.customer = ObjectPath.get(
            response,
            "data.customer.id"
          );
          this.credit_create.invoice = ObjectPath.get(
            response,
            "data.invoice.id"
          );
          this.credit_create.description = ObjectPath.get(
            response,
            "data.description"
          );
          this.credit_create.reason = ObjectPath.get(response, "data.reason");
          this.credit_create.property_person_notify = ObjectPath.get(
            response,
            "data.property_person_notify"
          );
          this.credit_create.billing_person_notify = ObjectPath.get(
            response,
            "data.billing_person_notify"
          );
          this.credit_create.barcode = ObjectPath.get(response, "data.barcode");
          this.credit_create.reference = ObjectPath.get(
            response,
            "data.reference"
          );
          this.credit_create.credit_note_date = ObjectPath.get(
            response,
            "data.credit_note_date"
          );
          this.credit_create.discount_value = ObjectPath.get(
            response,
            "data.discount_value"
          );
          this.credit_create.discount_value_type = ObjectPath.get(
            response,
            "data.discount_value_type"
          );
          this.credit_create.tax_applied = ObjectPath.get(
            response,
            "data.tax_applied"
          );
          this.credit_create.adjustment = ObjectPath.get(
            response,
            "data.adjustment"
          );

          this.update_data = {
            term_conditions: ObjectPath.get(response, "data.term_conditions"),
            admin_remark: ObjectPath.get(response, "data.admin_remark"),
            client_remark: ObjectPath.get(response, "data.client_remark"),
            notify_admin: ObjectPath.get(response, "data.notify_admin"),
            notify_customer: ObjectPath.get(response, "data.notify_customer"),
            notify_engineer: false,
            documents: [],
          };

          this.get_line_items({ "credit-note": this.creditId });

          this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Credit Note", route: "credit-note" },
            { title: "Update" },
            { title: ObjectPath.get(response, "data.barcode") },
          ]);
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.page_loaded = true;
        });
    },
    getOptions() {
      this.page_loading = true;
      this.$store
        .dispatch(QUERY, {
          url: "credit-note/options",
          data: {
            customer: this.customerId,
            invoice: this.invoiceId,
          },
        })
        .then((response) => {
          this.customer = ObjectPath.get(response, "data.customer");
          this.invoice = ObjectPath.get(response, "data.invoice");
          this.property = ObjectPath.get(response, "data.property");
          this.billing = ObjectPath.get(response, "data.billing");
          this.property_contact_person = ObjectPath.get(
            response,
            "data.property_contact_person"
          );
          this.billing_contact_person = ObjectPath.get(
            response,
            "data.billing_contact_person"
          );

          this.credit_create.barcode = ObjectPath.get(response, "data.barcode");
          this.credit_create.reference = `INVOICE # ${this.invoice.barcode}`;
          this.credit_create.discount_value = ObjectPath.get(
            response,
            "data.invoice.discount_value"
          );
          this.credit_create.discount_value_type = ObjectPath.get(
            response,
            "data.invoice.discount_value_type"
          );
          this.credit_create.tax_applied = ObjectPath.get(
            response,
            "data.invoice.tax_applied"
          );
          this.credit_create.adjustment = ObjectPath.get(
            response,
            "data.invoice.adjustment"
          );

          this.update_data = {
            term_conditions: ObjectPath.get(response, "data.term_conditions"),
            admin_remark: ObjectPath.get(response, "data.admin_remark"),
            client_remark: ObjectPath.get(response, "data.client_remark"),
            notify_admin: ObjectPath.get(response, "data.notify_admin"),
            notify_customer: ObjectPath.get(response, "data.notify_customer"),
            notify_engineer: false,
            documents: [],
          };

          this.initLineItems();
        })
        .catch((error) => {
          this.logError(error);
          this.forcePush = true;
          this.$nextTick(() => {
            this.goBack();
          });
        })
        .finally(() => {
          this.page_loading = false;
          this.loadContent();
        });
    },
  },
  mounted() {
    this.getOptions();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Credit Note", route: "credit-note" },
      { title: "Create" },
    ]);

    TermConditionEventBus.$on("update:term-condition", (argument) => {
      this.terms_condition = argument;
    });

    NoteAttachmentEventBus.$on("update:notes-attachment", (argument) => {
      this.note_attachment = argument;
    });
  },
  beforeDestroy() {
    TermConditionEventBus.$off("update:term-condition");
    NoteAttachmentEventBus.$off("update:notes-attachment");
  },
  beforeMount() {
    this.creditId = ObjectPath.get(this.$route, "params.id");
    this.invoiceId = ObjectPath.get(this.$route, "query.invoice");
    this.customerId = ObjectPath.get(this.$route, "query.customer");
  },
  beforeRouteLeave(to, from, next) {
    const _this = this;
    if (_this.forcePush) {
      next();
    } else if (_this.credit_note_created && _this.line_item_created) {
      next();
    } else {
      _this.routePreventDialog = true;
      PreventDialogEventBus.$on("stay-on-page", function () {
        _this.routePreventDialog = false;
        next(false);
      });
      PreventDialogEventBus.$on("leave-page", function () {
        _this.routePreventDialog = false;
        next();
      });
    }
  },
};
</script>
