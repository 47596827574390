<template>
  <v-container fluid>
    <v-form
      ref="passwordForm"
      v-model.trim="formValid"
      lazy-validation
      v-on:submit.stop.prevent="onSubmit"
    >
      <v-row>
        <input type="hidden" v-model.trim="formData.engineer" />
        <v-col md="6" class="py-0" offset-md="3">
          <v-text-field
            :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show2 ? 'text' : 'password'"
            v-on:click:append="show2 = !show2"
            dense
            filled
            label="New Password"
            solo
            flat
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password"
            :rules="[
              validateRules.required(formData.password, 'New Password'),
              validateRules.minLength(formData.password, 'New Password', 8),
              validateRules.maxLength(formData.password, 'New Password', 16),
            ]"
          ></v-text-field>
        </v-col>
        <v-col md="6" class="py-0" offset-md="3">
          <v-text-field
            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show3 ? 'text' : 'password'"
            v-on:click:append="show3 = !show3"
            dense
            filled
            label="Confirm Password"
            solo
            flat
            color="cyan"
            hint="At least 8 characters"
            v-model.trim="formData.password_confirmation"
            :rules="[
              validateRules.required(
                formData.password_confirmation,
                'Confirm Password'
              ),
              validateRules.confirmPassword(
                formData.password_confirmation,
                'Confirm Password',
                formData.password
              ),
              validateRules.minLength(
                formData.password_confirmation,
                'Confirm Password',
                8
              ),
              validateRules.maxLength(
                formData.password_confirmation,
                'Confirm Password',
                16
              ),
            ]"
          ></v-text-field>
        </v-col>
        <v-col class="text-right" md="6" offset-md="3">
          <v-btn
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="onSubmit"
            class="mx-2 custom-grey-border custom-bold-button text-white"
            color="cyan"
          >
            Update
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import { PATCH } from "@/core/services/store/request.module";
export default {
  name: "password-update",
  mixins: [ValidationMixin],
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      formValid: true,
      formLoading: false,
      formData: {
        engineer: null,
        password: null,
        password_confirmation: null,
      },
    };
  },
  methods: {
    onSubmit() {
      const _this = this;
      if (!_this.$refs.passwordForm.validate()) {
        return false;
      }

      _this.formLoading = true;
      _this.engineer = _this.$route.params.id;
      let url = "engineer/" + _this.engineer + "/password";
      _this.$store
        .dispatch(PATCH, {
          url,
          data: _this.formData,
        })
        .then(() => {
          _this.formValid = true;
          _this.formData = {
            password: null,
            password_confirmation: null,
          };
          _this.$emit("password:updated");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    mounted() {
      const _this = this;
      _this.formData.engineer = this.$route.params.id;
    },
    created() {
      const _this = this;
      _this.engineer = _this.$route.params.id;
    },
  },
};
</script>
