var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CreateUpdateTemplate',{attrs:{"routePreventDialog":_vm.routePreventDialog,"customClass":"bug-create create-form"},scopedSlots:_vm._u([{key:"header-title",fn:function(){return [_c('h1',{staticClass:"form-title d-flex"},[_vm._v("Submit a new Bug")])]},proxy:true},{key:"header-action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.formLoading},on:{"click":_vm.goBack}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.formLoading,"loading":_vm.formLoading,"color":"cyan"},on:{"click":_vm.onSubmit}},[_vm._v(" Submit ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"bugForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"custom-content-container pt-0",attrs:{"cols":"12","md":9,"sm":9}},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('table',{staticClass:"width-100"},[_c('tbody',[_c('tr',[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"width":"200"}},[_c('label',{staticClass:"font-size-16 font-weight-600",attrs:{"for":"bug-title"}},[_vm._v("Title")])]),_c('td',[_c('v-text-field',{attrs:{"dense":"","id":"bug-title","filled":"","label":"Title","solo":"","flat":"","color":"cyan","rules":[
                              _vm.validateRules.required(
                                _vm.bugSubmit.title,
                                'Title'
                              ),
                              _vm.validateRules.minLength(
                                _vm.bugSubmit.title,
                                'Title',
                                2
                              ),
                              _vm.validateRules.maxLength(
                                _vm.bugSubmit.title,
                                'Title',
                                255
                              ) ]},model:{value:(_vm.bugSubmit.title),callback:function ($$v) {_vm.$set(_vm.bugSubmit, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bugSubmit.title"}})],1)]),_c('tr',[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"width":"200"}},[_c('label',{staticClass:"font-size-16 font-weight-600",attrs:{"for":"bug-priority"}},[_vm._v("Priority")])]),_c('td',[_c('v-select',{attrs:{"id":"bug-priority","items":_vm.priorityList,"dense":"","filled":"","item-text":"text","item-value":"value","item-color":"cyan","label":"Priority","solo":"","flat":"","color":"cyan","rules":[
                              _vm.validateRules.required(
                                _vm.bugSubmit.priority,
                                'Priority'
                              ) ]},model:{value:(_vm.bugSubmit.priority),callback:function ($$v) {_vm.$set(_vm.bugSubmit, "priority", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"bugSubmit.priority"}})],1)]),_c('tr',[_c('td',{staticStyle:{"vertical-align":"top"},attrs:{"width":"200"}},[_c('label',{staticClass:"font-size-16 font-weight-600",attrs:{"for":"bug-description"}},[_vm._v("Description")])]),_c('td',[_c('editor',{model:{value:(_vm.bugSubmit.description),callback:function ($$v) {_vm.$set(_vm.bugSubmit, "description", $$v)},expression:"bugSubmit.description"}})],1)]),_c('tr',[_c('td',{staticClass:"pt-7"},[_c('label',{staticClass:"font-size-16 font-weight-600"},[_vm._v("Attachment(s)")])]),_c('td',{staticClass:"pt-7"},[_c('FileTemplate',{attrs:{"allowUpload":""},on:{"file:updated":_vm.updateFiles}})],1)])])])])],1)],1)],1),_c('v-col',{staticClass:"custom-sidebar-container grey lighten-3",attrs:{"cols":"12","md":"3","sm":"3"}},[_vm._l((_vm.fieldDescriptions),function(fieldInfo,indx){return [(fieldInfo.field == _vm.currentActiveField)?_c('div',{key:indx,staticClass:"help-sidebar"},[_c('div',{staticClass:"help-sidebar-header"},[(fieldInfo.title)?_c('h3',{staticClass:"section-header-title"},[_vm._v(" "+_vm._s(fieldInfo.title)+" ")]):_vm._e()]),(fieldInfo.description)?_c('p',{staticClass:"help-sidebar-description"},[_vm._v(" "+_vm._s(fieldInfo.description)+" ")]):_vm._e(),(fieldInfo.footer)?_c('h5',{staticClass:"help-sidebar-footer-title"},[_vm._v(" "+_vm._s(fieldInfo.footer)+" ")]):_vm._e(),(fieldInfo.footer_content)?_c('p',{staticClass:"help-sidebar-footer-text"},[_vm._v(" "+_vm._s(fieldInfo.footer_content)+" ")]):_vm._e()]):_vm._e()]})],2)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }