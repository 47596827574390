var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"custom-page-header-count pt-0 px-0"},[_c('v-layout',[(_vm.dataLoading)?[_vm._l((_vm.dataLoadingList),function(data,index){return [_c('v-flex',{key:index,staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.dataLoadingList[index + 1],
            'mx-1': index != 0 && _vm.dataLoadingList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-' +
              data.headercolor},[_c('div',{staticClass:"card-body p-0"},[_c('div',{class:'card-rounded-bottom mid_part '},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-' + data.headercolor},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(
                              'media/custom-svg/' + _vm.moduleType + '.svg'
                            )}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right"},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2 min-width-150px"},[_c('v-skeleton-loader',{staticClass:"custom-skeleton",attrs:{"type":"text"}})],1),_c('span',{class:'qnt_val font-weight-bolder text-' + data.headercolor},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":data.headercolor}})],1)])])])])])])]})]:[_vm._l((_vm.statusList),function(data,index){return [(data.top_visible)?_c('v-flex',{key:index,staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.statusList[index + 1],
            'mx-1': index != 0 && _vm.statusList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-' +
              data.headercolor},[_c('div',{staticClass:"card-body p-0"},[_c('div',{class:'card-rounded-bottom mid_part shadow-sm bg-light-' +
                  data.color +
                  ' bg-front-' +
                  data.textcolor},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-' + data.color},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(
                              'media/custom-svg/' + _vm.moduleType + '.svg'
                            )}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right bTitle"},[_c('router-link',{attrs:{"to":_vm.getDefaultRoute(_vm.moduleType, {
                          query: { status: data.value },
                        })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var route = ref.route;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2"},[_vm._v(" "+_vm._s(data.text)+" ")]),(data.value == 'all')?[_c('span',{class:'qnt_val font-weight-bolder text-' +
                              data.headercolor},[_vm._v(_vm._s(data[_vm.allkey]))])]:[_c('span',{class:'qnt_val font-weight-bolder text-' +
                              data.headercolor},[_vm._v(_vm._s(data[_vm.countkey]))])]],2)]}}],null,true)})],1)])])])])]):_vm._e()]}),_vm._l((_vm.extraList),function(data,index){return [_c('v-flex',{key:("extra-" + index),staticClass:"py-0",class:{
            'mr-1': index == 0,
            'ml-1': !_vm.extraList[index + 1],
            'mx-1': index != 0 && _vm.extraList[index + 1],
          }},[_c('div',{class:'card m-0 top_card card-custom gutter-b border-primary'},[_c('div',{staticClass:"card-body p-0"},[_c('div',{staticClass:"card-rounded-bottom mid_part"},[_c('div',{staticClass:"d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"},[_c('span',{staticClass:"symbol symbol-circle symbol-50 symbol-light-danger mr-2"},[_c('span',{staticClass:"symbol-label"},[_c('span',{class:'svg-icon svg-icon-xl svg-icon-primary'},[_c('inline-svg',{attrs:{"src":_vm.$assetURL(
                              'media/custom-svg/' + _vm.moduleType + '.svg'
                            )}})],1)])]),_c('div',{staticClass:"d-flex flex-column text-right bTitle"},[_c('router-link',{attrs:{"to":_vm.getDefaultRoute(_vm.moduleType, {
                          query: { status: data.status_key },
                        })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var href = ref.href;
                      var route = ref.route;
                      var navigate = ref.navigate;
                      var isActive = ref.isActive;
                      var isExactActive = ref.isExactActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('h5',{staticClass:"text-dark-75 font-weight-bold mt-2"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c('span',{class:'qnt_val font-weight-bolder text-primary'},[_vm._v(_vm._s(data.value))])])]}}],null,true)})],1)])])])])])]})]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }