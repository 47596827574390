import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
// import router from "@/router";
import JwtService from "@/core/services/jwt.service";
import {
  ErrorEventBus,
  SuccessEventBus,
  InitializeError,
} from "@/core/lib/message.lib";
import { TopBarLoaderEventBus } from "@/core/lib/topbar.loader.lib";
import { setCookie } from "@/core/plugins/bt-cookie";

/**
 * Service to call HTTP request via Axios
 */

// ** For Refreshing Token
let isAlreadyFetchingAccessToken = false;

let subscribers = [];

let authToken = JwtService.getToken();

let tokenType = JwtService.getTokenType();

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    Vue.axios.defaults.headers.common["Accept"] = "application/json";
    Vue.axios.defaults.trailingSlash = false;

    let numberOfAjaxCAllPending = 0;

    // Add a request interceptor
    Vue.axios.interceptors.request.use(
      function (config) {
        if (config.url.endsWith("/")) {
          config.url = config.url.slice(0, -1);
        }
        numberOfAjaxCAllPending++;
        TopBarLoaderEventBus.$emit("start:loader");
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    Vue.axios.interceptors.response.use(
      (response) => {
        if (
          response.data &&
          response.data.message &&
          response.data.with_message
        ) {
          SuccessEventBus.$emit("update:success", response.data.message);
        }

        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending == 0) {
          TopBarLoaderEventBus.$emit("done:loader");
        }

        return response;
      },
      (error) => {
        numberOfAjaxCAllPending--;
        if (numberOfAjaxCAllPending == 0) {
          TopBarLoaderEventBus.$emit("fail:loader");
        }

        ErrorEventBus.$emit("update:error", InitializeError(error.response));

        /* Manage Refresh Token Begin */
        const { config, response } = error;
        const originalRequest = config;

        // ** if (status === 401) {
        if (authToken && tokenType && response && response.status === 401) {
          try {
            if (!isAlreadyFetchingAccessToken) {
              isAlreadyFetchingAccessToken = true;
              Vue.axios
                .post("auth/refresh-token")
                .then((response) => {
                  const { data } = response.data;
                  authToken = data.access_token;
                  tokenType = data.token_type;
                  setCookie({ key: "access_token", value: data.access_token });
                  setCookie({ key: "expires_at", value: data.expires_in });
                  setCookie({ key: "token_type", value: data.token_type });
                  subscribers = subscribers.filter((callback) =>
                    callback(authToken)
                  );
                })
                .catch(() => {
                  authToken = null;
                  tokenType = null;
                  setCookie({ key: "access_token", value: null });
                  setCookie({ key: "expires_at", value: null });
                  setCookie({ key: "token_type", value: null });
                  JwtService.destroyToken();
                  setTimeout(function () {
                    window.location = process.env.VUE_APP_BASE_URL;
                  }, 500);
                })
                .finally(() => {
                  isAlreadyFetchingAccessToken = false;
                  Vue.axios.defaults.headers.common[
                    "Authorization"
                  ] = `${tokenType} ${authToken}`;
                });
            }
            const retryOriginalRequest = new Promise((resolve) => {
              subscribers.push((accessToken) => {
                originalRequest.headers.Authorization = `${tokenType} ${accessToken}`;
                resolve(axios.request(originalRequest));
              });
            });
            return retryOriginalRequest;
          } catch (error) {
            authToken = null;
            tokenType = null;
            setCookie({ key: "access_token", value: null });
            setCookie({ key: "expires_at", value: null });
            setCookie({ key: "token_type", value: null });
            JwtService.destroyToken();
            setTimeout(function () {
              window.location = process.env.VUE_APP_BASE_URL;
            }, 500);
          }
        }
        /* Manage Refresh Token End */

        return Promise.reject(error);
      }
    );
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    // const token = JwtService.getToken();
    if (authToken && typeof authToken === "string") {
      Vue.axios.defaults.headers.common[
        "Authorization"
      ] = `bearer ${authToken}`;
    }
    //Vue.axios.defaults.withCredentials = true;
  },

  /**
   * Set the custom HTTP request headers
   */
  setCustomHeader(token) {
    if (token && typeof token === "string") {
      Vue.axios.defaults.headers.common["Authorization"] = `bearer ${token}`;
    }
    //Vue.axios.defaults.withCredentials = true;
  },

  query(resource, data) {
    return Vue.axios.get(resource, { params: data });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`).catch((error) => {
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(resource, params);
  },

  upload(resource, params) {
    return Vue.axios.post(resource, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  },

  download(resource, params) {
    Vue.axios.defaults.headers.common["Accept"] = "*/*";
    return Vue.axios.get(resource, {
      responseType: "blob",
      params,
    });
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(resource, params);
  },

  /**
   * Send the PATCH HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  patch(resource, params) {
    return Vue.axios.patch(resource, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, data) {
    return Vue.axios.delete(resource, {
      data: data,
    });
  },
};

export default ApiService;
