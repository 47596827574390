<template>
  <v-container v-if="$hasWarranty" fluid class="px-0 py-0">
    <v-checkbox
      hide-details
      color="cyan"
      v-model="warrantyOption.status"
      v-on:change="initDialog($event)"
      class="mt-0 mb-2"
      :readonly="readonly"
    >
      <template v-slot:label>
        <span class="color-custom-blue font-weight-700">Warranty ?</span>
      </template>
    </v-checkbox>
    <v-btn
      small
      depressed
      :disabled="!warrantyOption.status"
      color="cyan white--text"
      class="custom-bold-button w-100"
      v-on:click="manageWarranty()"
    >
      <template v-if="readonly"
        ><v-icon small left>mdi-tag-text mdi-flip-h</v-icon> View
        Warranty</template
      >
      <template v-else
        ><v-icon small left>mdi-cog</v-icon> Manage Warranty</template
      >
    </v-btn>
    <template>
      <Dialog :common-dialog="warrantyDialog" :dialog-width="dialogWidth">
        <template v-slot:title>
          <template v-if="!readonly">Manage</template> Warranty
        </template>
        <template v-slot:body>
          <v-form
            ref="warrantyForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="updateWarranty"
          >
            <v-container fluid>
              <v-row>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600 required">Start Date</label>
                  <Datepicker
                    flat
                    solo
                    mandatory
                    :readonly="readonly"
                    placeholder="Start Date"
                    :default-date="warrantyOption.start_date"
                    v-model="warrantyOption.start_date"
                  ></Datepicker>
                </v-col>
                <v-col md="6" class="py-0">
                  <label class="font-weight-600 required">Serial No.</label>
                  <v-text-field
                    dense
                    filled
                    :rules="[
                      () =>
                        !!warrantyOption.unique_id || 'Serial No. is required',
                    ]"
                    color="cyan"
                    label="Serial No."
                    solo
                    flat
                    :readonly="readonly"
                    v-model.trim="warrantyOption.unique_id"
                  ></v-text-field>
                </v-col>
                <v-col md="12" class="py-0 mt-2">
                  <label class="font-weight-600">Remarks</label>
                  <v-textarea
                    v-model.trim="warrantyOption.description"
                    auto-grow
                    dense
                    filled
                    solo
                    flat
                    :readonly="readonly"
                    color="cyan"
                    label="Remarks"
                    row-height="20"
                  ></v-textarea>
                </v-col>
              </v-row>
              <table width="50%">
                <tr>
                  <td :colspan="readonly ? 2 : 3">
                    <label class="font-weight-600">Duration</label>
                  </td>
                </tr>
                <tr
                  v-for="(warranty, index) in defaultItemWarranty"
                  :key="index"
                >
                  <td>
                    <v-text-field
                      dense
                      v-mask="'###'"
                      filled
                      label="Duration"
                      :rules="[
                        () => !!warranty.value || 'Duration is required',
                      ]"
                      solo
                      flat
                      :readonly="readonly"
                      color="cyan"
                      class="pr-2 width-100"
                      v-model.trim="warranty.value"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      :items="warrantyDurationTypes"
                      dense
                      filled
                      solo
                      flat
                      :readonly="readonly"
                      :rules="[
                        () => !!warranty.field || 'Duration Type is required',
                      ]"
                      class="pl-2 pr-2 width-100"
                      v-model.trim="warranty.field"
                      label="Duration Type"
                      color="cyan"
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                    ></v-select>
                  </td>
                  <td v-if="!readonly" style="vertical-align: top">
                    <template v-if="index > 0">
                      <v-btn
                        class="mx-2"
                        color="red lighten-1"
                        dark
                        fab
                        small
                        v-on:click="removeWarranty(index)"
                      >
                        <v-icon dark> mdi-minus</v-icon>
                      </v-btn>
                    </template>
                    <template v-else>
                      <v-btn
                        class="mx-2"
                        color="cyan"
                        dark
                        fab
                        small
                        v-on:click="pushWarranty()"
                      >
                        <v-icon dark> mdi-plus</v-icon>
                      </v-btn>
                    </template>
                  </td>
                </tr>
              </table>
            </v-container>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            v-if="!readonly"
            :disabled="!formValid"
            v-on:click="updateWarranty()"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            >Save
          </v-btn>
          <v-btn
            v-on:click="warrantyDialog = false"
            class="mx-2 custom-grey-border custom-bold-button"
            >Close
          </v-btn>
        </template>
      </Dialog>
    </template>
  </v-container>
</template>

<style scoped>
.v-dialog__content {
  align-items: start;
}
</style>

<script>
// import { WarrantyEventBus } from "@/core/lib/warranty/warranty.lib";
import Dialog from "@/view/pages/partials/Dialog.vue";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";
import { toSafeInteger, isEmpty, isArray } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "manage-warranty",
  model: {
    prop: "warranty",
    event: "input",
  },
  props: {
    warranty: {
      type: Object,
      default() {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    warranty: {
      deep: true,
      handler() {
        this.init(false);
      },
    },
  },
  data() {
    return {
      formValid: true,
      warrantyDialog: false,
      warrantyOption: {
        status: false,
        start_date: moment().format("YYYY-MM-DD"),
        unique_id: null,
        description: null,
      },
      defaultItemWarranty: [],
      warrantyDurationTypes: [
        { text: "Day(s)", value: "day" },
        { text: "Week(s)", value: "week" },
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
    };
  },
  methods: {
    manageWarranty() {
      this.init(false);
      this.$nextTick(() => {
        this.warrantyDialog = true;
      });
    },
    init(status) {
      const warranty = this.warranty;
      if (warranty && !isEmpty(warranty)) {
        this.warrantyOption.status = status ? true : warranty.status;
        this.warrantyOption.start_date = warranty.start_date;
        this.warrantyOption.unique_id = warranty.unique_id;
        this.warrantyOption.description = warranty.description;
        this.defaultItemWarranty = [];
        if (
          warranty.duration &&
          isArray(warranty.duration) &&
          warranty.duration.length
        ) {
          for (let z = 0; z < warranty.duration.length; z++) {
            this.defaultItemWarranty.push({
              value: warranty.duration[z].value,
              field: warranty.duration[z].field,
            });
          }
        }
      }
    },
    initDialog(status) {
      if (status) {
        this.init(true);
        this.$nextTick(() => {
          this.warrantyDialog = true;
        });
      } else {
        this.voidWarranty();
      }
    },
    removeWarranty(index) {
      if (this.readonly) {
        return false;
      }

      this.defaultItemWarranty.splice(index, 1);
    },
    voidWarranty() {
      if (this.readonly) {
        return false;
      }

      this.warrantyDialog = false;
      this.warrantyOption.status = false;
      this.emitInput();
    },
    emitInput() {
      if (this.readonly) {
        return false;
      }

      this.$nextTick(() => {
        const warranty = {
          status: this.warrantyOption.status,
          start_date: this.warrantyOption.start_date,
          unique_id: this.warrantyOption.unique_id,
          description: this.warrantyOption.description,
          duration: this.defaultItemWarranty,
        };
        this.$emit("input", warranty);
        this.$emit("update:warranty", warranty);
      });
    },
    pushWarranty() {
      if (this.readonly) {
        return false;
      }

      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          value: null,
          field: null,
        });
      }
    },
    updateWarranty() {
      if (this.readonly) {
        return false;
      }

      if (!this.$refs.warrantyForm.validate()) {
        return false;
      }

      this.emitInput();

      this.$emit("change", true);

      this.warrantyDialog = false;
    },
  },
  components: {
    Dialog,
    Datepicker,
  },
  mounted() {
    this.init(false);
  },
  computed: {
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
};
</script>
