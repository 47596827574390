import ApiService from "@/core/services/api.service";

export const GET_COUNTRY = "getCountry";
export const GET_STATE = "getState";
export const GET_CITY = "getCity";
export const GET_BRANDS = "getBrands";
export const GET_MANUFACTURER = "getManufacturer";
export const GET_UOM = "getUom";
export const GET_WAREHOUSES = "getWarehouses";
export const GET_SUPPLIERS = "getSuppliers";
export const GET_CUSTOMERS = "getCustomers";
export const GET_CATEGORY = "getCategory";
export const GET_TAXES = "getTaxes";
export const GET_LOCATIONS = "getLocations";
export const GET_ADJUSTMENT_REASONS = "getAdjustmentReasons";

export const SET_ERROR = "setError";
export const SET_MESSAGE = "setMessage";
export const SET_DATA = "setData";

export const SET_CC_PERSONS = "setCCPersons";

const state = {
  errors: {},
  message: {},
  data: [],
  cc_persons: [],
};

const getters = {
  cc_persons(state) {
    return state.cc_persons;
  },
};

const commitErrors = (response) => {
  let requestErrors = [];
  if (response && response.status === 422) {
    for (let error in response.data) {
      if (response.data[error]) {
        for (let i = response.data[error].length - 1; i >= 0; i--) {
          requestErrors.push(response.data[error][i]);
        }
      }
    }
  } else if (response && response.status === 500) {
    requestErrors.push(response.data.message);
  } else {
    requestErrors.push(response.toString());
  }
  return requestErrors;
};

const actions = {
  [SET_CC_PERSONS](state, payload) {
    state.commit(SET_CC_PERSONS, payload);
  },
  [GET_COUNTRY](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("countries", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_STATE](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("states", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_CITY](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("cities", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_BRANDS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("brand", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_MANUFACTURER](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("manufacturer", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_UOM](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("uom", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_WAREHOUSES](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("warehouse", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_SUPPLIERS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("supplier", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_CUSTOMERS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("customers", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_CATEGORY](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("category", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_LOCATIONS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("locations", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_ADJUSTMENT_REASONS](context, slug) {
    return new Promise((resolve, reject) => {
      ApiService.get("item-adjustments/reasons", slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
  [GET_TAXES](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("taxes")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          if (error && error.response) {
            context.commit(SET_ERROR, commitErrors(error.response));
            reject(error.response);
          } else {
            context.commit(SET_ERROR, commitErrors(error));
            reject(error);
          }
        });
    });
  },
};

const mutations = {
  [SET_CC_PERSONS](state, payload) {
    state.cc_persons = payload;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_MESSAGE](state, data) {
    state.message = data;
  },
  [SET_DATA](state, data) {
    state.data = data;
    state.errors = {};
    state.message = {};
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
