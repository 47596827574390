import ApiService from "@/core/services/api.service";
import LocalService from "@/core/services/local.service";

const AppConfiguration = {
  init() {
    ApiService.get("configuration").then(({ data }) => {
      if (data && data.data) {
        LocalService.saveEncrypt("line-item-config", data.data.line_item);
        LocalService.saveData("app-config", {
          phoneMask: "+65-####-####",
          entity: data.data.entity,
          dateFormat: data.data.date_format,
          dateTimeFormat: data.data.date_format + " " + data.data.time_format,
          timeFormat: data.data.time_format,
          currency: { code: "SGD", symbol: "$" },
          CurrencyFormat: {
            decimal: ".",
            number: ",",
            precision: 2,
            symbol: "$",
          },
          QuantityFormat: { decimal: ".", number: ",", precision: 2 },
          NumberFormat: { decimal: ".", number: ",", precision: 2 },
        });
      }
    });
  },
  get() {
    return LocalService.getData("app-config");
  },
  getLineItemConfig() {
    return LocalService.getEncrypt("line-item-config");
  },
};

export default AppConfiguration;
