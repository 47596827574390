var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"menu-nav"},[_vm._l((_vm.MenuList),function(row,index){return [(_vm.lodash.isArray(row))?[_c('li',{key:index,staticClass:"menu-item menu-item-submenu",class:{
          'menu-item-open menu-item-open-active': _vm.isChildActive(row),
        },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + _vm.getGroupTitle(row) + '.svg')}})],1),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.getGroupTitle(row)))]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v(_vm._s(_vm.getGroupTitle(row)))])])]),_vm._l((row),function(rowd,indexd){return [_c('router-link',{key:indexd,class:{
                  'menu-item-active': _vm.isRouteActive(rowd.menu),
                  'disable-link': _vm.disableLink(rowd.menu),
                },attrs:{"to":rowd.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(rowd.title))])])])]}}],null,true)})]})],2)])])]:_c('router-link',{key:index,class:{
        'menu-item-active': _vm.isRouteActive(row.menu),
        'disable-link': _vm.disableLink(row.menu),
      },attrs:{"to":row.route},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('v-tooltip',{attrs:{"right":"","content-class":"custom-right-tooltip menu-tooltip-hover hide-menu-tooltip-hover"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('li',_vm._g(_vm._b({staticClass:"menu-item",class:{
              'menu-item-active': _vm.isRouteActive(row.menu),
              'disable-link': _vm.disableLink(row.menu),
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},'li',attrs,false),on),[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/' + row.icon + '.svg')}})],1),_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(_vm.getMenuTitle(row)))])])])]}}],null,true)},[_c('span',{staticClass:"menu-tooltip-hover-target"},[_vm._v(_vm._s(_vm.getMenuTitle(row)))])])]}}],null,true)})]}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{ 'menu-item-open menu-item-open-active': _vm.isReportChildActive() },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('span',{staticClass:"svg-icon svg-icon-lg svg-custom-menu-icon mr-4"},[_c('inline-svg',{attrs:{"src":_vm.$assetURL('media/custom-svg/report.svg')}})],1),_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v("Reports")]),_c('i',{staticClass:"menu-arrow"})]),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_vm._m(0),_c('router-link',{class:{
            'menu-item-active': _vm.isRouteActive('ageing-report'),
            'disable-link': _vm.disableLink('ageing-report'),
          },attrs:{"to":{
            name: 'admin.ageing-report',
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var href = ref.href;
          var navigate = ref.navigate;
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('i',{staticClass:"menu-bullet menu-bullet-dot"},[_c('span')]),_c('span',{staticClass:"menu-text"},[_vm._v("Ageing")])])])]}}])})],1)])])],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"menu-item menu-item-parent",attrs:{"aria-haspopup":"true"}},[_c('span',{staticClass:"menu-link"},[_c('span',{staticClass:"menu-text text-capitalize"},[_vm._v("Reports")])])])}]

export { render, staticRenderFns }