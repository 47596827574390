<template>
  <v-container fluid class="service-detail-height">
    <v-row>
      <v-col cols="6" class="pb-0 pl-4">
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr>
              <th colspan="2" class="font-size-18 py-2">Basic Information</th>
            </tr>
            <tr
              v-for="(field, index) in tOneFields"
              :key="'tOneFields' + index"
            >
              <td class="font-size-18 font-weight-500 py-2" width="250">
                {{ field.title }}
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="field.child && detail[field.key]">
                    {{ detail[field.key][field.child] }}
                  </template>
                  <template v-else-if="detail[field.key]">
                    {{ detail[field.key] }}
                  </template>
                  <template v-else>
                    <em class="text--secondary">No {{ field.title }}</em>
                  </template>
                </template>
              </td>
            </tr>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Sellable
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  {{ detail.is_sellable ? "Yes" : "No" }}
                </template>
              </td>
            </tr>
            <template v-if="false">
              <tr>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Tax Preference
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    {{ detail.is_taxable ? "Taxable" : "Non-Taxable" }}
                  </template>
                </td>
              </tr>
              <tr v-if="detail.is_taxable">
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Tax
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <template v-if="detail.tax"
                      >{{ detail.tax.name }} [{{
                        detail.tax.percentage
                      }}%]</template
                    >
                    <template v-else
                      ><em class="text--secondary">No Tax</em></template
                    >
                  </template>
                </td>
              </tr>
              <tr v-else>
                <td class="font-size-18 font-weight-500 py-2" width="250">
                  Tax Exemption
                </td>
                <td class="font-weight-600 font-size-18 py-2">
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    <template v-if="detail.tax_exemption"
                      >{{ detail.tax_exemption.name }} [{{
                        detail.tax_exemption.reason
                      }}]</template
                    >
                    <template v-else
                      ><em class="text--secondary"
                        >No Tax Exemption</em
                      ></template
                    >
                  </template>
                </td>
              </tr>
            </template>
            <tr>
              <td class="font-size-18 font-weight-500 py-2" width="250">
                Charges Cost
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-if="dataLoading">
                  <v-skeleton-loader
                    class="custom-skeleton width-80"
                    type="text"
                  >
                  </v-skeleton-loader>
                </template>
                <template v-else>
                  <template v-if="dataLoading">
                    <v-skeleton-loader
                      class="custom-skeleton width-80"
                      type="text"
                    >
                    </v-skeleton-loader>
                  </template>
                  <template v-else>
                    {{ $accountingJS.formatMoney(detail.charges) }}
                  </template>
                </template>
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
      </v-col>
      <v-col cols="6" class="pb-0 grey lighten-4 pl-4">
        <perfect-scrollbar
          ref="sidebarScrollbar"
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="max-height: 89vh position: relative"
        >
          <table class="width-100">
            <tr v-if="lodash.isEmpty(detail.warranty_data) === false">
              <th colspan="2" class="font-size-18 py-2">
                Warranty Information
              </th>
            </tr>
            <tr v-if="lodash.isEmpty(detail.warranty_data) === false">
              <td class="font-size-18 font-weight-500 py-2" width="200">
                Duration
              </td>
              <td class="font-weight-600 font-size-18 py-2">
                <template v-for="(warranty, index) in detail.warranty_data">
                  <span
                    :key="'warranty-data-' + index"
                    class="text--uppercase pr-2"
                    >{{ warranty.value }} {{ warranty.field }}</span
                  >
                </template>
              </td>
            </tr>
          </table>

          <div
            class="mt-4"
            :class="{
              'custom-border-top':
                lodash.isEmpty(detail.warranty_data) === false,
            }"
          >
            <v-row>
              <v-col class="pt-0" md="8">
                <p class="font-size-18 font-weight-700 py-2 pl-1 my-0">
                  Service Summary
                </p>
              </v-col>
              <v-col class="pt-0" md="4">
                <v-select
                  :items="summaryFilter"
                  :disabled="dataLoading"
                  :loading="dataLoading"
                  v-model="summary"
                  hide-details
                  color="cyan"
                  item-color="cyan"
                  class="pt-0 mt-0 custom-appex-filter"
                  item-text="text"
                  item-value="value"
                  v-on:change="getSummary(true)"
                >
                </v-select>
              </v-col>
              <v-col md="6" offset-md="3" v-if="dataLoading">
                <v-progress-linear
                  color="cyan"
                  class="my-20"
                  indeterminate
                  rounded
                  height="6"
                ></v-progress-linear>
              </v-col>
              <v-col md="12" v-else class="text-center">
                <template v-if="series.length > 0">
                  <apexchart
                    type="bar"
                    :options="chartOptions"
                    :series="series"
                  ></apexchart>
                </template>
                <template v-else
                  ><em class="text--secondary font-size-16"
                    >Nothing Found...</em
                  ></template
                >
              </v-col>
            </v-row>
          </div>
        </perfect-scrollbar>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      product: 0,
      dataLoading: true,
      summary: "this_week",
      summaryFilter: [
        { text: "This Week", value: "this_week" },
        { text: "Previous Week", value: "previous_week" },
        { text: "This Month", value: "this_month" },
        { text: "Previous Month", value: "previous_month" },
        /*{ text: "This Quarter", value: "this_quarter" },
        { text: "Previous Quarter", value: "previous_quarter" },*/
        { text: "This Year", value: "this_year" },
        { text: "Previous Year", value: "previous_year" },
      ],
      chartOptions: {
        chart: {
          id: "service-summary",
        },
        xaxis: {
          categories: [],
        },
        dataLabels: {
          enabled: true,
          style: {
            fontFamily: "'Poppins', Helvetica, Arial, sans-serif",
          },
        },
      },
      series: [],
      tOneFields: [
        {
          title: "Name",
          key: "name",
          child: false,
        },
        {
          title: "Reference",
          key: "reference",
          child: false,
        },
        {
          title: "Service #",
          key: "barcode",
          child: false,
        },
        {
          title: "Description",
          key: "description",
          child: false,
        },
      ],
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        this.product = this.lodash.toSafeInteger(param.id);
        this.getSummary(false);
      },
    },
  },
  methods: {
    getSummary(scrollBottom) {
      const _this = this;
      if (_this.product > 0) {
        _this.dataLoading = true;
        _this.series = [];
        _this.$store
          .dispatch(QUERY, {
            url: "product/" + _this.product + "/summary",
            data: {
              filter: _this.summary,
            },
          })
          .then(({ data }) => {
            let categories = Object.values(data.categories);
            if (
              _this.lodash.isEmpty(categories) === false &&
              _this.lodash.isArray(categories)
            ) {
              _this.chartOptions = {
                ..._this.chartOptions,
                ...{
                  xaxis: {
                    categories: categories.map((row) => {
                      if (
                        _this.summary == "this_year" ||
                        _this.summary == "previous_year"
                      ) {
                        return moment(row).format("MMMM, YYYY");
                      }
                      return moment(row).format("Do, MMMM, YYYY");
                    }),
                  },
                },
              };

              if (_this.lodash.isEmpty(data.series) === false) {
                if (
                  _this.lodash.isEmpty(data.series.quotation_counted) === false
                ) {
                  _this.series.push({
                    name: "Quotation",
                    data: Object.values(data.series.quotation_counted),
                  });
                }
                if (
                  _this.lodash.isEmpty(data.series.invoice_counted) === false
                ) {
                  _this.series.push({
                    name: "Invoice",
                    data: Object.values(data.series.invoice_counted),
                  });
                }
                if (_this.lodash.isEmpty(data.series.job_counted) === false) {
                  _this.series.push({
                    name: "Job",
                    data: Object.values(data.series.job_counted),
                  });
                }
              }
            }
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
            if (_this.lodash.isEmpty(_this.series) === false && scrollBottom) {
              let objDiv = _this.$refs["sidebarScrollbar"].$el;
              _this.$nextTick(() => {
                objDiv.scrollTop = objDiv.scrollHeight;
              });
            }
          });
      }
    },
  },
  mounted() {
    this.getSummary();
  },
};
</script>
