var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-0",attrs:{"fluid":""}},[_c('v-container',{staticClass:"p-0 m-0"},[_c('v-row',[_c('v-col',{attrs:{"md":"3"}},[_c('v-text-field',{attrs:{"clearable":"","dense":"","filled":"","flat":"","label":"Search","hide-details":"","solo":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('search')},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getAllProducts.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.getAllProducts.apply(null, arguments)}]},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.search"}})],1),_c('v-col',{attrs:{"md":"3"}},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.dataLoading,"loading":_vm.dataLoading},on:{"click":_vm.getAllProducts}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Search")])])],1)],1)],1),_c('ListingTable',{attrs:{"columnCount":3,"dataLoading":_vm.dataLoading,"rowData":_vm.dataRows},scopedSlots:_vm._u([{key:"thead",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th",attrs:{"width":"100"}},[_vm._v("Service #")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Service's Info")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("Price")])])])]},proxy:true},{key:"tbody",fn:function(){return [_c('tbody',{staticClass:"custom-border-bottom custom-border-top"},[(_vm.dataRows.length > 0)?_vm._l((_vm.dataRows),function(row,index){return _c('tr',{key:index,attrs:{"link":""},on:{"click":function($event){_vm.$router.push(
                _vm.getDefaultRoute('service.detail', {
                  params: { id: row.id },
                })
              )}}},[_c('td',{staticClass:"simple-table-td"},[_c('Barcode',{attrs:{"route":"service.detail","barcode":row.barcode,"id":row.id}})],1),_c('td',{staticClass:"simple-table-td"},[_c('div',{staticClass:"product-listing-customer"},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Name: ")]),_vm._v(" "+_vm._s(row.name || " - ")+" ")]),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Reference: ")]),(row.reference)?[_vm._v(_vm._s(row.reference))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Reference")])]],2)])]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"100"}},[_c('div',{staticClass:"product-listing-invoice"},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_c('b',[_vm._v("Charges: ")]),(row.charges)?[_vm._v(_vm._s(row.charges))]:[_c('em',{staticClass:"text--secondary"},[_vm._v("No Selling Price")])]],2)])])])}):(!_vm.dataLoading)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no service at the moment. ")])])]):_vm._e()],2)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }