<template>
  <v-container
    fluid
    class="white-background main-detail-page"
    :class="[customClass]"
  >
    <div
      v-if="$slots['header-title']"
      class="p-5 pb-0"
      :class="[customSecondClass]"
    >
      <v-layout class="d-flex pb-2 flex-wrap justify-content-between">
        <v-flex class="pt-0 my-auto" style="max-width: 50%">
          <slot name="header-title"></slot>
        </v-flex>
        <v-flex class="pt-0 text-right">
          <slot name="header-action"></slot>
        </v-flex>
      </v-layout>
    </div>
    <slot name="body"></slot>
    <slot name="footer"></slot>
  </v-container>
</template>

<script>
export default {
  props: {
    customClass: {
      type: String,
      default: null,
    },
    customSecondClass: {
      type: String,
      default: null,
    },
  },
};
</script>
