<template>
  <v-container fluid class="ageing-report pt-0">
    <div class="layout my-4">
      <div
        v-for="(row, index) in statusList"
        :key="index"
        class="flex py-0 mx-4"
      >
        <div class="card m-0 top_card card-custom gutter-b border-primary">
          <div class="card-body p-0">
            <div
              class="card-rounded-bottom mid_part shadow-sm bg-light-cyan bg-front-white"
            >
              <div
                class="d-flex align-items-center justify-content-between card-spacer pb-2 flex-grow-1"
              >
                <span
                  class="symbol symbol-circle symbol-50 symbol-light-danger mr-2"
                >
                  <span class="symbol-label">
                    <span class="svg-icon svg-icon-xl svg-icon-cyan">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/report.svg')"
                      />
                    </span>
                  </span>
                </span>
                <div class="d-flex flex-column text-right bTitle">
                  <router-link
                    :to="
                      getDefaultRoute('ageing-report', {
                        query: { status: row.value },
                      })
                    "
                    v-slot="{ href, route, navigate, isActive, isExactActive }"
                  >
                    <a :href="href" v-on:click="navigate">
                      <h5 class="text-dark-75 font-weight-bold mt-2">
                        {{ row.text }}
                      </h5>
                      <span class="qnt_val font-weight-bolder text-primary">{{
                        row.status_count
                      }}</span>
                    </a>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-container fluid class="white-background main-listing-page px-4">
      <v-layout class="pb-2">
        <v-flex md6>
          <h1 class="form-title d-flex margin-auto">
            <v-select
              :disabled="dataLoading"
              :items="statusList"
              v-model="status"
              hide-details
              item-color="cyan"
              class="pt-0 mt-0 listing-status main-listing-status-filter"
              item-text="text"
              item-value="value"
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.text"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.description"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip :color="item.color" :text-color="item.textcolor">
                    <template>{{ item.status_count }}</template>
                  </v-chip>
                </v-list-item-action>
              </template>
            </v-select>
          </h1>
        </v-flex>
        <v-flex md6 class="text-right">
          <v-btn
            color="cyan white--text"
            :disabled="dataLoading"
            v-on:click="requestDialog = true"
            class="custom-bold-button"
          >
            New Request
          </v-btn>
        </v-flex>
      </v-layout>
      <div class="custom-listing-table">
        <v-simple-table class="simple-table custom-table-height" fixed-header>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="simple-table-th" width="10%">Request #</th>
                <th class="simple-table-th" width="30%">Customer</th>
                <th class="simple-table-th">Date</th>
                <th class="simple-table-th">Reference</th>
                <th class="simple-table-th" width="10%">File</th>
                <th class="simple-table-th" width="10%">Status</th>
                <th class="simple-table-th" width="10%">Request Date</th>
                <th class="simple-table-th" width="10%">Request By</th>
              </tr>
            </thead>
            <tbody v-if="dataLoading">
              <tr v-for="count in 15" :key="count">
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
                <td height="35" valign="middle">
                  <v-skeleton-loader type="text"></v-skeleton-loader>
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="rows.length">
              <tr
                v-for="(row, index) in rows"
                :key="index"
                class="cursor-default"
              >
                <td class="simple-table-td cursor-default">
                  <span class="d-flex">
                    <Barcode :barcode="row.barcode" />
                    <v-chip
                      class="ml-4"
                      color="lime darken-4 white--text"
                      content="Report"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                      }"
                      v-if="row.type == 1"
                      >R</v-chip
                    >
                    <v-chip
                      class="ml-4"
                      color="teal lighten-1 white--text"
                      content="Statement"
                      v-tippy="{
                        arrow: true,
                        arrowType: 'round',
                        animation: 'fade',
                      }"
                      v-if="row.type == 2"
                      >S</v-chip
                    >
                  </span>
                </td>
                <td class="simple-table-td cursor-default">
                  <span v-if="row.customer_barcode" class="text-truncate">
                    <Barcode
                      :barcode="row.customer_barcode"
                      route="customer.detail"
                      :id="row.customer_id"
                    />
                    <span class="pl-2">{{ row.customer_display_name }}</span>
                  </span>
                  <span v-else class="text--secondary"
                    ><i class="font-size-18">No Customer</i></span
                  >
                </td>
                <td class="simple-table-td cursor-default">
                  {{ formatDate(row.r_date) }}
                </td>
                <td class="simple-table-td cursor-default">
                  <span v-if="row.reference">{{ row.reference }}</span>
                  <span v-else class="text--secondary"
                    ><i class="font-size-18">No Reference</i></span
                  >
                </td>
                <td class="simple-table-td cursor-default">
                  <span v-if="row.exl_file || row.pdf_file">
                    <v-icon
                      color="cyan"
                      class="mx-2"
                      v-on:click="downloadFile(row.id, 'excel')"
                      v-if="row.exl_file"
                      >mdi-file-excel</v-icon
                    >
                    <v-icon
                      color="cyan"
                      class="mx-2"
                      v-on:click="downloadFile(row.id, 'pdf')"
                      v-if="row.pdf_file"
                      >mdi-file-pdf</v-icon
                    >
                  </span>
                  <span v-else class="text--secondary"
                    ><i class="font-size-18">Not Generated</i></span
                  >
                </td>
                <td class="simple-table-td cursor-default">
                  <v-chip
                    style="max-height: 22px"
                    :color="getStatusColor(row.status)"
                    text-color="white"
                    label
                  >
                    {{ getStatusText(row.status) }}
                  </v-chip>
                </td>
                <td class="simple-table-td cursor-default">
                  <v-chip
                    class="mx-2 my-1 custom-grey-border"
                    color="blue-grey darken-1"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon>mdi-clock-outline</v-icon>
                    </v-avatar>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ formatDateTime(row.added_at) }}
                    </p>
                  </v-chip>
                </td>
                <td class="simple-table-td cursor-default">
                  <v-chip
                    class="mx-2 my-1 custom-grey-border"
                    color="blue-grey darken-1"
                    text-color="white"
                  >
                    <v-avatar left>
                      <v-icon>mdi-account-circle</v-icon>
                    </v-avatar>
                    <p class="m-0 custom-nowrap-ellipsis">
                      {{ row.added_by_display_name }}
                    </p>
                  </v-chip>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="8">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no request at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </v-container>
    <Dialog :commonDialog="requestDialog">
      <template v-slot:title>Request Ageing Report</template>
      <template v-slot:body>
        <v-container>
          <v-radio-group
            class="mb-4"
            color="cyan"
            dense
            hide-details
            v-model="request.type"
            row
          >
            <v-radio color="cyan" label="Ageing Report" :value="1"></v-radio>
            <v-radio color="cyan" label="Ageing Statement" :value="2"></v-radio>
          </v-radio-group>
          <v-layout>
            <v-flex md4 class="my-auto">
              <label
                class="font-weight-600 font-size-18 my-0 mb-0 m-0"
                for="request-customer"
                :class="{ required: request.type == 2 }"
                >Customer</label
              >
            </v-flex>
            <v-flex md8>
              <v-autocomplete
                id="request-customer"
                v-model="request.customer"
                dense
                filled
                label="Select Customer"
                placeholder="Select Customer"
                solo
                flat
                :items="customerList"
                item-text="display_name"
                item-value="id"
                :disabled="requestLoading"
                :loading="requestLoading"
                item-color="cyan"
                color="cyan"
                hide-details
                clearable
              ></v-autocomplete>
            </v-flex>
          </v-layout>
          <v-layout class="my-2">
            <v-flex md4 class="my-auto">
              <label
                class="font-weight-600 font-size-18 my-0 mb-0 m-0"
                for="request-reference"
                >Reference</label
              >
            </v-flex>
            <v-flex md8>
              <v-text-field
                id="request-reference"
                v-model="request.reference"
                dense
                filled
                label="Enter Reference"
                placeholder="Enter Reference"
                solo
                flat
                :disabled="requestLoading"
                :loading="requestLoading"
                color="cyan"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex md4 class="my-auto">
              <label
                class="font-weight-600 font-size-18 my-0 mb-0 m-0"
                for="request-date"
                >Date</label
              >
            </v-flex>
            <v-flex md8>
              <DatePicker
                solo
                flat
                label="Select Date"
                placeholder="Select Date"
                id="request-date"
                :disabled="requestLoading"
                :loading="requestLoading"
                :default-date="request.date"
                v-model="request.date"
              ></DatePicker>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2">
            <v-flex md4 class="my-auto">
              <label
                class="font-weight-600 font-size-18 my-0 mb-0 m-0"
                for="request-email"
                >Email
                <v-icon
                  content="Report and Notification will be on given email"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                  }"
                  small
                  >mdi-information-outline</v-icon
                ></label
              >
            </v-flex>
            <v-flex md8>
              <v-text-field
                id="request-email"
                v-model="request.email"
                dense
                filled
                label="Enter Email"
                placeholder="Enter Email"
                solo
                flat
                :disabled="requestLoading"
                :loading="requestLoading"
                color="cyan"
                hide-details
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout class="mt-2">
            <v-flex md4 class="my-auto"> </v-flex>
            <v-flex md8>
              <span class="font-size-12 text--red font-weight-600 mx-2"
                >Note:
                <i class="font-size-12 text--red font-weight-600"
                  >Once report is generated then, notification will be send on
                  given email.</i
                ></span
              >
            </v-flex>
          </v-layout>
        </v-container>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="requestLoading"
          :loading="requestLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          color="cyan white--text"
          v-on:click="submitRequest()"
        >
          Save
        </v-btn>
        <v-btn
          :disabled="requestLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="requestDialog = false"
        >
          Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Dialog from "@/view/pages/partials/Dialog";
import DatePicker from "@/view/pages/partials/Datepicker.vue";
import moment from "moment-timezone";
import { GET, POST, QUERY } from "@/core/services/store/request.module";
import ObjectPath from "object-path";
import { ErrorEventBus, ClearEventBus } from "@/core/lib/message.lib";
import Barcode from "@/view/pages/partials/Barcode.vue";
import { getToken } from "@/core/services/jwt.service.js";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "ageing-report",
  mixins: [CommonMixin],
  data() {
    return {
      requestDialog: false,
      dataLoading: true,
      requestLoading: false,
      rows: [],
      customerList: [],
      request: {
        type: 1,
        customer: null,
        reference: null,
        date: moment().format("YYYY-MM-DD"),
        email: null,
      },
      statusList: [
        {
          value: "all",
          text: "All Requests",
          description: null,
          color: null,
          textcolor: null,
          status_count: 0,
        },
        {
          value: "pending",
          text: "Pending",
          description: null,
          color: null,
          textcolor: null,
          status_count: 0,
        },
        {
          value: "completed",
          text: "Completed",
          description: null,
          color: null,
          textcolor: null,
          status_count: 0,
        },
      ],
      status: "all",
    };
  },
  watch: {
    "$route.query"() {
      this.getRequests();
    },
  },
  methods: {
    downloadFile(id, type) {
      const token = getToken();
      const url = `${this.$apiURL}report/ageing/${id}/download/${type}?token=${token}`;
      window.open(url, "_blank");
    },
    getStatusText(status) {
      if (status == 1) {
        return "Pending";
      }
      if (status == 2) {
        return "In Progress";
      }
      if (status == 3) {
        return "Completed";
      }
      if (status == 4) {
        return "No Rows";
      }
    },
    getStatusColor(status) {
      if (status == 1) {
        return "cyan";
      }
      if (status == 2) {
        return "orange";
      }
      if (status == 3) {
        return "green";
      }
      if (status == 4) {
        return "red";
      }
    },
    submitRequest() {
      ClearEventBus.$emit("clear:all", true);

      if (!this.request.date) {
        ErrorEventBus.$emit("update:error", "Please select valid date");
        return false;
      }

      if (this.request.type == 2 && !this.request.customer) {
        ErrorEventBus.$emit("update:error", "Please select customer");
        return false;
      }

      this.requestLoading = true;

      this.$store
        .dispatch(POST, { url: "report/ageing", data: this.request })
        .then(() => {
          this.getRequests();
          this.requestDialog = false;
          this.request = {
            customer: null,
            reference: null,
            date: moment().format("YYYY-MM-DD"),
            email: null,
          };
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.requestLoading = false;
        });
    },
    getCustomers() {
      this.$store
        .dispatch(GET, { url: "customer-list" })
        .then((response) => {
          const output = ObjectPath.get(response, "data");
          if (output && output.length) {
            this.customerList = output.map((row) => {
              return {
                display_name: `${row.barcode} - ${row.display_name}`,
                id: row.id,
              };
            });
          }
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    getRequests() {
      this.dataLoading = true;
      this.$store
        .dispatch(QUERY, { url: "report/ageing", data: this.$route.query })
        .then((response) => {
          this.rows = ObjectPath.get(response, "data.rows");
          this.statusList = ObjectPath.get(response, "data.status");
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
  },
  components: {
    Dialog,
    Barcode,
    DatePicker,
  },
  mounted() {
    this.getCustomers();
    this.getRequests();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Ageing Report",
      },
    ]);
  },
};
</script>
