<template>
  <Dialog :commonDialog="closerDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      Closer
      <v-icon small class="mx-4 color-custom-blue"
        >mdi-checkbox-blank-circle</v-icon
      >
      <template v-if="lodash.isEmpty(visitDetail) === false"
        >Visit# {{ visitDetail.barcode }}</template
      >
      <template v-else>Job# {{ detail.barcode }}</template>
    </template>
    <template v-slot:body>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <v-form
          ref="ticketCloserForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="updateOrCreate('')"
        >
          <v-container class="py-0">
            <v-row class="mx-4">
              <v-col md="12">
                <p class="font-weight-500">Details</p>
                <v-textarea
                  v-model.trim="data.details"
                  :rules="[validateRules.required(data.details, 'Details')]"
                  auto-grow
                  dense
                  filled
                  flat
                  label="Details"
                  solo
                  color="cyan"
                  hide-details
                  row-height="20"
                ></v-textarea>
              </v-col>
              <v-col md="12" v-if="$haveRecordAudio">
                <p class="font-weight-500">Record Audio</p>
                <RecordAudio
                  v-model="audioDataURL"
                  v-on:start-recording="recordingStartedUpdate"
                  v-on:stop-recording="recordingStoppedUpdate"
                ></RecordAudio>
              </v-col>
              <v-col md="12">
                <p class="font-weight-500">Attachments</p>
                <FileTemplate
                  allowUpload
                  isMinDisplay
                  v-on:file:updated="updateFiles"
                ></FileTemplate>
              </v-col>
              <v-col md="12">
                <table width="100%">
                  <tr>
                    <td>
                      <v-checkbox
                        v-model="has_payment"
                        hide-details
                        color="cyan"
                        label="Collect Payment?"
                      ></v-checkbox>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                  <tr v-if="has_payment">
                    <td width="25%">
                      <p class="m-0 mx-2 font-weight-500">
                        Amount to be collected
                      </p>
                    </td>
                    <td width="25%">
                      <p class="m-0 mx-2 font-weight-500">Collected amount</p>
                    </td>
                    <td width="25%">
                      <p class="m-0 mx-2 font-weight-500">Payment Mode</p>
                    </td>
                    <td v-if="false" width="25%">
                      <p class="m-0 mx-2 font-weight-500">Status</p>
                    </td>
                  </tr>
                  <tr v-if="has_payment">
                    <td width="25%">
                      <v-text-field
                        readonly
                        dense
                        filled
                        prepend-inner-icon="mdi-currency-usd"
                        label="Amount to be collected"
                        solo
                        flat
                        color="cyan"
                        v-model="detail.total"
                      ></v-text-field>
                    </td>
                    <td width="25%">
                      <v-text-field
                        dense
                        filled
                        label="Collected amount"
                        solo
                        flat
                        color="cyan"
                        v-on:keypress="validate_amount()"
                        prepend-inner-icon="mdi-currency-usd"
                        v-model="collected_amount"
                        :rules="[
                          validateRules.required(
                            collected_amount,
                            'Collected amount'
                          ),
                        ]"
                      ></v-text-field>
                    </td>
                    <td width="25%">
                      <v-select
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="payment_mode_list"
                        label="Payment Mode"
                        item-text="text"
                        item-value="id"
                        solo
                        flat
                        class="pl-2 width-100"
                        v-model.trim="payment_mode"
                      >
                      </v-select>
                    </td>
                    <td v-if="false" width="25%">
                      <v-select
                        dense
                        filled
                        color="cyan"
                        item-color="cyan"
                        :items="status_list"
                        label="Status"
                        solo
                        flat
                        class="pl-2 width-100"
                        v-model.trim="collected_status"
                      >
                      </v-select>
                    </td>
                  </tr>
                </table>
              </v-col>
              <v-col md="12">
                <v-row>
                  <v-col md="6" class="custom-border-right">
                    <p class="font-weight-500">
                      Engineer's Signature
                      <v-btn
                        content="Click here to clear signature"
                        v-tippy="{ arrow: true, placement: 'top' }"
                        icon
                        small
                        class="float-right"
                        v-on:click="engineerSignature.clear()"
                      >
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </p>
                    <div
                      id="engineer-signature"
                      class="engineer-signature custom-border-grey-dashed"
                    >
                      <canvas ref="engineersignature"></canvas>
                    </div>
                  </v-col>
                  <v-col md="6">
                    <p class="font-weight-500">
                      Customer's Signature
                      <v-btn
                        content="Click here to clear signature"
                        v-tippy="{ arrow: true, placement: 'top' }"
                        icon
                        small
                        class="float-right"
                        v-on:click="customerSignature.clear()"
                      >
                        <v-icon>mdi-close-circle-outline</v-icon>
                      </v-btn>
                    </p>
                    <div
                      id="customer-signature"
                      class="customer-signature custom-border-grey-dashed"
                    >
                      <canvas ref="customersignature"></canvas>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </perfect-scrollbar>
    </template>
    <template v-slot:action>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate('mark_as_complete')"
      >
        Mark As Complete
      </v-btn>
      <v-btn
        :disabled="!formValid || formLoading || pageLoading"
        :loading="formLoading"
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="updateOrCreate('stop_timer')"
      >
        Hold and Stop Timer
      </v-btn>
      <template v-if="false">
        <v-menu
          transition="slide-y-transition"
          top
          content-class="custom-menu-list"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="!formValid || formLoading || pageLoading"
              :loading="formLoading"
              class="mx-2 custom-grey-border custom-bold-button white--text"
              color="cyan"
              v-bind="attrs"
              v-on="on"
            >
              Hold and... <v-icon right>mdi-chevron-up</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-tooltip top content-class="custom-top-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item
                  link
                  v-on:click="updateOrCreate('stop_timer')"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                    <v-icon class="icon-default-blue"
                      >mdi-clock-check-outline</v-icon
                    >
                  </v-list-item-icon>
                  <v-list-item-title class="font-weight-500 font-size-14"
                    >Stop Timer</v-list-item-title
                  >
                </v-list-item>
              </template>
              <span>Hold and Stop Timer</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </template>
      <v-btn
        :disabled="formLoading || pageLoading"
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="closeDialog()"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import CreateTicketCloserMixin from "@/core/lib/ticket-closer/create.ticket.closer.mixin";
import FileTemplate from "@/view/pages/partials/FileTemplate";
import RecordAudio from "@/view/pages/partials/RecordAudio";

export default {
  mixins: [CreateTicketCloserMixin],
  components: {
    FileTemplate,
    RecordAudio,
  },
  watch: {
    collected_amount() {
      this.validate_amount();
    },
  },
  methods: {
    validate_amount() {
      const total = this.lodash.toSafeInteger(this.detail.total);
      const collected_amount = this.lodash.toSafeInteger(this.collected_amount);
      if (collected_amount >= total) {
        this.$nextTick(() => {
          this.collected_amount = total;
        });
      }
    },
    closeDialog() {
      this.engineerSignature.clear();
      this.customerSignature.clear();
      this.$nextTick(() => {
        this.$emit("close-dialog", true);
      });
    },
  },
};
</script>
