<template>
  <v-chip
    class="ma-2 custom-status font-weight-500 text-uppercase custom-grey-border"
    text-color="green"
    color="green"
    v-if="status"
    label
    outlined
    :small="small"
  >
    Active
  </v-chip>
  <v-chip
    class="ma-2 custom-status font-weight-500 text-uppercase custom-grey-border"
    text-color="red lighten-1"
    color="red lighten-1"
    v-else
    label
    outlined
    :small="small"
  >
    In-Active
  </v-chip>
</template>

<script>
export default {
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    small: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
