\<template>
  <DetailTemplate
    :customClass="'user-detail detail-page'"
    v-if="getPermission('user:view')"
  >
    <template v-slot:header-title>
      <v-skeleton-loader
        class="custom-skeleton"
        v-if="pageLoading"
        width="400"
        height="50"
        type="text"
      >
      </v-skeleton-loader>
      <h1 class="form-title custom-nowrap-ellipsis text-capitalize">
        {{ getPageTitle }}
      </h1>
    </template>
    <template v-slot:header-action>
      <template v-if="getPermission('user:update')">
        <v-btn
          :disabled="pageLoading || roleLoading"
          :loading="roleLoading"
          class="mx-2 custom-grey-border custom-bold-button text-white"
          color="cyan"
          v-on:click="getRoles"
        >
          <v-icon small left>mdi-pencil</v-icon>
          Edit
        </v-btn>
      </template>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="goBack"
      >
        <v-icon small left>mdi-keyboard-backspace</v-icon>
        Back
      </v-btn>
    </template>
    <template v-slot:body>
      <v-tabs
        v-model="userTab"
        background-color="transparent"
        color="cyan"
        class="custom-tab-transparent"
      >
        <template v-for="(tab, index) in tabs">
          <v-tab
            class="font-size-16 font-weight-600 px-8"
            :key="index"
            :href="'#tab-' + tab.key"
            :disabled="tab.disabled"
          >
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
              <!--begin::Svg Icon-->
              <inline-svg
                :src="$assetURL('media/custom-svg/' + tab.key + '.svg')"
              />
              <!--end::Svg Icon-->
            </span>
            {{ tab.title }}
          </v-tab>
        </template>
      </v-tabs>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll user-detail-height"
        style="position: relative"
      >
        <v-tabs-items v-model="userTab">
          <v-tab-item value="tab-history">
            <RecentActivity :user="user"></RecentActivity>
          </v-tab-item>
          <v-tab-item value="tab-password">
            <PasswordUpdate
              :user="user"
              v-on:password:updated="userTab = 'tab-history'"
            ></PasswordUpdate>
          </v-tab-item>
          <v-tab-item value="tab-signature">
            <br />
            <v-container class="ml-4">
              <v-layout style="max-width: 50%">
                <v-flex md6>
                  <v-img
                    contain
                    v-if="attachment_url"
                    :lazy-src="$defaultImage"
                    :src="attachment_url"
                  ></v-img>
                  <v-img
                    v-else
                    contain
                    :lazy-src="$defaultImage"
                    :src="$defaultImage"
                  ></v-img>
                  <br />
                  <v-btn
                    v-on:click="select_file()"
                    color="cyan white--text"
                    class="custom-bold-button"
                    label
                    >Change</v-btn
                  >
                  <v-file-input
                    ref="select-file"
                    class="d-none"
                    accept="image/png, image/jpeg, image/jpg"
                    v-model="signature"
                    dense
                    v-on:change="on_file_change($event)"
                    hide-details
                  ></v-file-input>
                </v-flex>
                <v-flex md6> </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>
          <v-tab-item value="tab-login-log">
            <LoginLog :user="user" :logs="loginLogs"></LoginLog>
          </v-tab-item>
        </v-tabs-items>
      </perfect-scrollbar>

      <Dialog :commonDialog="updateDialog">
        <template v-slot:title> Edit User </template>
        <template v-slot:body>
          <v-form
            ref="userForm"
            v-model.trim="formValid"
            lazy-validation
            v-on:submit.stop.prevent="submitUser"
          >
            <v-row class="create-form">
              <v-col md="10" class="py-0 pt-4" offset-md="1">
                <v-row dense>
                  <v-col md="4" class="py-0">
                    <label>Salutation</label>
                    <v-select
                      :items="salutationList"
                      dense
                      filled
                      item-text="text"
                      item-value="value"
                      item-color="cyan"
                      label="Salutation"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.title"
                      :rules="[
                        validateRules.required(updateUser.title, 'Salutation'),
                      ]"
                    ></v-select>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label>First Name</label>
                    <v-text-field
                      dense
                      filled
                      label="First Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.first_name"
                      :rules="[
                        validateRules.required(
                          updateUser.first_name,
                          'First Name'
                        ),
                        validateRules.minLength(
                          updateUser.first_name,
                          'First Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.first_name,
                          'First Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="4" class="py-0">
                    <label>Last Name</label>
                    <v-text-field
                      dense
                      filled
                      label="Last Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.last_name"
                      :rules="[
                        validateRules.required(
                          updateUser.last_name,
                          'Last Name'
                        ),
                        validateRules.minLength(
                          updateUser.last_name,
                          'Last Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.last_name,
                          'Last Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col md="10" class="py-0" offset-md="1">
                <v-row dense>
                  <v-col md="6" class="py-0">
                    <label>Role</label>
                    <v-select
                      :items="roles"
                      dense
                      filled
                      item-text="name"
                      item-value="id"
                      item-color="cyan"
                      label="Role"
                      solo
                      flat
                      v-model.trim="updateUser.role"
                      color="cyan"
                      hide-details
                      :rules="[validateRules.required(updateUser.role, 'Role')]"
                    ></v-select>
                    <em class="font-size-10" v-if="updateUser.role == 1"
                      >Unrestricted access to all modules.</em
                    >
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label>Display Name</label>
                    <v-text-field
                      dense
                      filled
                      label="Display Name"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.display_name"
                      :rules="[
                        validateRules.required(
                          updateUser.display_name,
                          'Display Name'
                        ),
                        validateRules.minLength(
                          updateUser.display_name,
                          'Display Name',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.display_name,
                          'Display Name',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-0">
                    <label>Email</label>
                    <v-text-field
                      dense
                      filled
                      label="Email"
                      solo
                      flat
                      color="cyan"
                      v-model.trim="updateUser.user_email"
                      :rules="[
                        validateRules.validEmail(
                          updateUser.user_email,
                          'Email'
                        ),
                        validateRules.required(updateUser.user_email, 'Email'),
                        validateRules.minLength(
                          updateUser.user_email,
                          'Email',
                          2
                        ),
                        validateRules.maxLength(
                          updateUser.user_email,
                          'Email',
                          100
                        ),
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-700 font-size-16"
                      >Designation</label
                    >
                    <v-autocomplete
                      :items="userDesignations"
                      v-model.trim="updateUser.designation"
                      dense.
                      filled
                      color="cyan"
                      item-color="cyan"
                      solo
                      flat
                      append-outer-icon="mdi-cog"
                      placeholder="Select Designation"
                      v-on:click:append-outer="manageDesignationDialog = true"
                      item-text="text"
                      item-value="value"
                      class="width-100"
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              v-html="'No Designation(s) Found.'"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-autocomplete>
                    <template>
                      <ManageUserdesignation
                        :dialog="manageDesignationDialog"
                        :user_designation="userDesignations"
                        v-on:close-dialog="manageDesignationDialog = false"
                        v-on:get-e-designation="getOptions"
                      ></ManageUserdesignation>
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-700 font-size-16"
                      >From Amount</label
                    >
                    <v-text-field
                      v-model.trim="updateUser.from_amount"
                      id="amount"
                      type="number"
                      dense
                      filled
                      label="From"
                      solo
                      flat
                      color="cyan"
                      v-on:keypress="
                        limitDecimal($event, updateUser.from_amount)
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col md="6" class="py-0">
                    <label class="font-weight-700 font-size-16"
                      >To Amount</label
                    >
                    <v-text-field
                      v-model.trim="updateUser.to_amount"
                      id="to"
                      type="number"
                      dense
                      filled
                      label="To"
                      solo
                      flat
                      color="cyan"
                      v-on:keypress="limitDecimal($event, updateUser.to_amount)"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row v-for="(type, index) in leaveList" :key="index">
                  <v-col md="6" class="pb-4">
                    <v-text-field
                      v-model.trim="type.category"
                      dense
                      filled
                      label="Category"
                      solo
                      flat
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="5" class="pb-4">
                    <v-text-field
                      v-model.trim="type.maxdays"
                      dense
                      filled
                      label="Max Days"
                      solo
                      flat
                      type="number"
                      min="0"
                      max="999"
                      hide-details
                      color="cyan"
                    ></v-text-field>
                  </v-col>
                  <v-col md="1" class="py-0 mt-2">
                    <v-checkbox
                      multiple
                      :disabled="pageLoading"
                      v-model="updateUser.categories"
                      color="cyan"
                      class="my-0"
                      dense
                      :value="type.id"
                      hide-details
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
        </template>
        <template v-slot:action>
          <v-btn
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            :disabled="!formValid || formLoading"
            :loading="formLoading"
            v-on:click="submitUser"
          >
            Update
          </v-btn>
          <v-btn
            :disabled="formLoading"
            class="custom-grey-border custom-bold-button"
            v-on:click="updateDialog = false"
          >
            Cancel
          </v-btn>
        </template>
      </Dialog>
    </template>
  </DetailTemplate>
</template>

<script>
import ManageUserdesignation from "@/view/pages/partials/Manage-Userdesignation.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import Dialog from "@/view/pages/partials/Dialog";
import { GET, PATCH, UPLOAD } from "@/core/services/store/request.module";
import LoginLog from "@/view/pages/user/detail/LoginLog";
import PasswordUpdate from "@/view/pages/user/detail/PasswordUpdate";
import RecentActivity from "@/view/pages/user/detail/RecentActivity";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ObjectPath from "object-path";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "user-detail",
  data() {
    return {
      userDesignations: [],
      user: 0,
      userTab: "tab-history",
      pageLoading: true,
      roleLoading: false,
      updateDialog: false,
      formValid: true,
      manageDesignationDialog: false,
      formLoading: false,
      loginLogs: [],
      leaveList: [],
      userArr: {},
      roles: [],
      tabs: [
        {
          title: "Recent Activities",
          icon: "mdi-credit-card",
          key: "history",
          disabled: false,
        },
        {
          title: "Login Log",
          icon: "mdi-credit-card",
          key: "login-log",
          disabled: false,
        },
        {
          title: "Signature",
          icon: "mdi-credit-card",
          key: "signature",
          disabled: false,
        },
        {
          title: "Password",
          icon: "mdi-credit-card",
          key: "password",
          disabled: false,
        },
      ],
      signature: null,
      attachment_url: null,
      updateUser: {
        role: null,
        title: null,
        first_name: null,
        last_name: null,
        display_name: null,
        user_email: null,
        designation: null,
        from_amount: 0,
        to_amount: 0,
      },
    };
  },
  methods: {
    select_file() {
      this.$refs["select-file"].$refs["input"].click();
    },
    on_file_change(file) {
      if (file) {
        this.attachment_url = null;
        let formData = new FormData();
        formData.append("files[0]", file, file.name);
        this.$store
          .dispatch(UPLOAD, { url: "file-manager/upload", data: formData })
          .then((response) => {
            this.attachment_url = ObjectPath.get(response, "data.0.file.url");
            this.$refs["select-file"].reset();
            this.update_signature();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    update_signature() {
      this.$store
        .dispatch(PATCH, {
          url: `user/${this.user}/signature/update`,
          data: { signature: this.attachment_url },
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    submitUser() {
      const _this = this;
      if (!_this.$refs.userForm.validate()) {
        return false;
      }

      _this.formLoading = true;

      _this.$store
        .dispatch(PATCH, {
          url: "user/" + _this.user,
          data: _this.updateUser,
        })
        .then(() => {
          _this.updateDialog = false;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.loadContent();
          _this.formLoading = false;
        });
    },
    /*updateOrcreate(categoriesid) {
      const _this = this; 
      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, { url: "setting/leave/" + categoriesid,
        data: {categories:_this.leaveList} })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },*/
    getUser() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "user/" + _this.user })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getOptions() {
      const _this = this;
      _this.$store
        .dispatch(GET, {
          url: "get-user-designation",
        })
        .then(({ data }) => {
          _this.userDesignations = this.lodash.map(
            data.user_designation,
            function (row) {
              return {
                text: row.text,
                value: row.id,
              };
            }
          );
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getRoles() {
      const _this = this;
      _this.roleLoading = true;
      _this.updateUser = {
        role: _this.userArr.role ? _this.userArr.role.id : 0,
        title: _this.lodash.lowerCase(_this.userArr.title),
        first_name: _this.userArr.first_name,
        last_name: _this.userArr.last_name,
        display_name: _this.userArr.display_name,
        user_email: _this.userArr.user_email,
        designation: _this.userArr.designation
          ? _this.userArr.designation.id
          : 0,
        from_amount: _this.userArr.from_amount,
        to_amount: _this.userArr.to_amount,
      };
      _this.$store
        .dispatch(GET, { url: "role" })
        .then(({ data }) => {
          _this.roles = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.updateDialog = true;
          _this.roleLoading = false;
        });
    },
    getLeaveDays() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "setting/leave",
        })
        .then(({ data }) => {
          _this.leaveList = data;
        })
        .catch((error) => {
          _this.logError(error);
          _this.goBack();
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    loadContent() {
      const _this = this;
      _this.pageLoading = true;
      _this
        .getUser()
        .then((response) => {
          _this.userArr = response;
          _this.attachment_url = response.signature;
          _this.loginLogs = response.login_details || [];
        })
        .catch((error) => {
          _this.logError(error);
          _this.$router.go(-1);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    ManageUserdesignation,
    DetailTemplate,
    LoginLog,
    Dialog,
    PasswordUpdate,
    RecentActivity,
  },
  mounted() {
    const _this = this;
    _this.getOptions();
    _this.getLeaveDays();
    _this.loadContent();
    _this.$store.dispatch(SET_BREADCRUMB, [
      { title: "User", route: "user" },
      { title: "Detail" },
    ]);
  },
  created() {
    const _this = this;
    _this.user = _this.lodash.toSafeInteger(_this.$route.params.id);
    if (!_this.user || _this.user <= 0) {
      _this.$router.go(-1);
    }
  },
  computed: {
    getPageTitle() {
      return this.userArr ? this.userArr.full_name : "";
    },
  },
};
</script>
<style>
.v-text-field--filled > .v-input__control > .v-input__slot {
  min-height: 100%;
}
.v-input input {
  max-height: 30px;
}
</style>
