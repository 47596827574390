var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"leave-template"},[_c('PageHeaderCount',{attrs:{"moduleType":"leave","dataLoading":_vm.dataLoading,"statusList":_vm.statusList,"allkey":"all_leaves","countkey":"leave_status_count"}}),_c('ListingTemplate',{attrs:{"customClass":'leave-listing'},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('ListingHeader',{scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.box)?[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('h1',{staticClass:"form-title custom-nowrap-ellipsis margin-auto selected-rows-text"},[_vm._v(" "+_vm._s(_vm.totalSelected)+" Selected ")])]),_c('v-col',{staticClass:"pt-0 justify-flex-end d-flex margin-auto",attrs:{"cols":"8"}},[[_c('v-menu',{attrs:{"content-class":"custom-menu-list","bottom":"","offset-y":"","close-on-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":_vm.deleteLeave}},'v-btn',attrs,false),[_vm._v(" Bulk Delete ")])]}}],null,false,102532048)},[_c('v-list')],1)],_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"red lighten-1 white--text"},on:{"click":_vm.clearSelections}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-close")]),_vm._v("Clear Selections ")],1)],2)]:[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"4"}},[_c('h1',{staticClass:"form-title d-flex margin-auto"},[_c('v-select',{staticClass:"pt-0 mt-0 listing-status main-listing-status-filter",attrs:{"disabled":_vm.dataLoading,"items":_vm.statusList,"hide-details":"","item-color":"cyan","item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.description)}})],1),_c('v-list-item-action',[_c('v-chip',{attrs:{"color":item.color,"text-color":item.textcolor}},[(item.value == 'all')?[_vm._v(_vm._s(item.all_leaves))]:[_vm._v(_vm._s(item.leave_status_count))]],2)],1)]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)]),_c('v-col',{staticClass:"pt-0 justify-flex-end d-flex margin-auto",attrs:{"cols":"8"}},[[_c('v-btn',{staticClass:"mx-2 custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":function($event){_vm.create_leave_dialog = true}}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create ")],1)],_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":function($event){_vm.searchDialog = true}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-filter")])],1),(false && !_vm.isEngineerChannel())?_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":function($event){_vm.$router.push(
                    _vm.getDefaultRoute('profile.setting', {
                      query: {
                        tab: 'leave',
                      },
                    })
                  )}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-cog")])],1):_vm._e(),_c('v-menu',{attrs:{"content-class":"custom-menu-list","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button",attrs:{"disabled":_vm.dataLoading || _vm.exportLoading,"color":"cyan white--text","loading":_vm.exportLoading}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-menu")])],1)]}}])},[_c('v-list',[_vm._l((_vm.moreActions),function(item,index){return [_c('v-list-item',{key:index,class:{ 'line-active': item.sort },attrs:{"link":""},on:{"click":function($event){return _vm.moreAction(item.action, item.sort)}}},[_c('v-list-item-title',[(item.icon)?_c('v-icon',{attrs:{"small":"","left":"","color":"cyan"}},[_vm._v(_vm._s(item.icon))]):_vm._e(),_vm._v(" "+_vm._s(item.title)+" "),(item.sort)?[(item.sort == 'asc')?_c('v-icon',{attrs:{"small":"","right":"","color":"cyan"}},[_vm._v("mdi-sort-ascending")]):_vm._e(),(item.sort == 'desc')?_c('v-icon',{attrs:{"small":"","right":"","color":"cyan"}},[_vm._v("mdi-sort-descending")]):_vm._e()]:_vm._e()],2)],1)]})],2)],1),_c('v-menu',{attrs:{"max-height":"400","max-width":"250","offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2 custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-table-edit")])],1)]}}])},[_c('draggable',{staticClass:"draggable-group",attrs:{"tag":"ul","handle":".draggable-drag-icon"},model:{value:(_vm.defaultColDefs),callback:function ($$v) {_vm.defaultColDefs=$$v},expression:"defaultColDefs"}},[_vm._l((_vm.defaultColDefs),function(cols){return [(!cols.checkbox)?_c('li',{key:cols.field,staticClass:"draggable-group-item"},[_c('v-checkbox',{staticClass:"pt-2 mt-0",attrs:{"dense":"","value":cols.field,"label":cols.headerName,"disabled":cols.fixed || _vm.dataLoading,"color":"cyan","hide-details":""},model:{value:(_vm.defaultColShow),callback:function ($$v) {_vm.defaultColShow=$$v},expression:"defaultColShow"}}),_c('v-icon',{staticClass:"draggable-drag-icon",attrs:{"small":"","right":"","color":"cyan"}},[_vm._v("mdi-drag")])],1):_vm._e()]})],2)],1),(false)?_c('PageTips',{attrs:{"module":"leave"}}):_vm._e()],2)]]},proxy:true}])}),(_vm.searchEnabled)?_c('ListingSearchCriteria',{attrs:{"pageModule":"Leave","basicSearchFields":['barcode', 'reference', 'subject'],"allowedSearchFields":_vm.searchableArray}}):_vm._e(),_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('LeaveKanban',{on:{"update:leave":_vm.updateLeave,"selected-rows":function($event){return _vm.update_selected($event)},"select-box":function (param) {
              _vm.box = param;
            }}})],1),_c('Dialog',{attrs:{"commonDialog":_vm.searchDialog},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Filter Leave")]},proxy:true},{key:"body",fn:function(){return [_c('perfect-scrollbar',{staticClass:"scroll custom-box-top-inner-shadow",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-container',{staticClass:"pt-0 custom-search-filter",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('DateRangePicker',{attrs:{"prop_label":"Duration Date Range"},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_vm._l((_vm.searchableArray),function(field,index){return [(field != 'dates')?_c('v-col',{key:index,attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"dense":"","filled":"","hide-details":"","label":_vm.getFilterLabel(field, 'Leave'),"solo":"","flat":"","clearable":"","color":"cyan"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.filterRows.apply(null, arguments)}},model:{value:(_vm.listingSearch[field]),callback:function ($$v) {_vm.$set(_vm.listingSearch, field, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"listingSearch[field]"}})],1):_vm._e()]})],2)],1)],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading,"color":"cyan white--text"},on:{"click":_vm.filterRows}},[_vm._v(" Filter ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"disabled":_vm.dataLoading},on:{"click":function($event){_vm.searchDialog = false}}},[_vm._v(" Close ")])]},proxy:true}])})]},proxy:true}])}),_c('CreateLeave',{attrs:{"drawer":_vm.create_leave_dialog,"leaveUpdateData":_vm.leaveCreate},on:{"close":function($event){_vm.create_leave_dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }