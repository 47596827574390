<template>
  <div v-if="!pageLoading" :class="{ 'mb-1': small }">
    <v-tooltip top content-class="custom-top-tooltip">
      <template v-slot:activator="{ on, attrs }">
        <v-chip
          v-bind="attrs"
          v-on="on"
          v-if="status > 0"
          class="mx-2 custom-status font-weight-600 custom-grey-border d-inline-table text-uppercase justify-center font-10"
          :color="param.color"
          :text-color="param.textcolor"
          label
          :small="small"
          :class="{ 'd-inline': small, 'max-height-24px': customerInvoice }"
        >
          {{ param.text }}
        </v-chip>
      </template>
      <span>Status</span>
    </v-tooltip>
  </div>
</template>

<script>
import { GET } from "@/core/services/store/request.module";
import LocalService from "@/core/services/local.service";

export default {
  data: () => {
    return {
      pageLoading: true,
      statusList: {},
      param: {
        color: null,
        textcolor: null,
        text: null,
      },
    };
  },
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    status: {
      type: Number,
      default: 0,
    },
    endpoint: {
      type: String,
      required: true,
      default: "",
    },
    customerInvoice: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    status: {
      deep: true,
      handler() {
        this.assignStatusList();
      },
    },
  },
  methods: {
    get_status_list() {
      const _this = this;
      return new Promise((resolve, reject) => {
        if (_this.endpoint) {
          let cache_response = LocalService.getData(_this.endpoint);
          if (_this.lodash.isEmpty(cache_response) === false) {
            resolve(cache_response);
          } else {
            _this.$store
              .dispatch(GET, { url: _this.endpoint })
              .then((response) => {
                resolve(response.data);
                LocalService.saveData(_this.endpoint, response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } else {
          resolve([]);
        }
      });
    },
    assignStatusList() {
      const _this = this;
      _this.get_status_list().then((response) => {
        if (_this.lodash.isEmpty(response) === false) {
          _this
            .setParams(response)
            .then((statusList) => {
              _this.param = statusList[_this.status];
            })
            .finally(() => {
              _this.pageLoading = false;
            });
        }
      });
    },
    setParams(response) {
      const _this = this;
      return new Promise((resolve) => {
        for (let i = response.length - 1; i >= 0; i--) {
          _this.statusList[response[i].status] = response[i];
          if (i === 0) {
            resolve(_this.statusList);
          }
        }
      });
    },
  },
  beforeDestroy() {
    this.param = { color: null, textcolor: null, text: null };
  },
  mounted() {
    this.assignStatusList();
  },
};
</script>
