<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <span
        class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1"
      >
        Hi,
      </span>
      <span
        class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3"
      >
        {{ CurrentUser.display_name }}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="false" alt="Pic" :src="CurrentUser.profile_image" />
        <span v-if="true" class="symbol-label font-size-h5 font-weight-bold">
          {{
            CurrentUser.display_name
              ? CurrentUser.display_name.charAt(0).toUpperCase()
              : ""
          }}
        </span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">User Profile</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <v-img
              class="profile-image"
              width="100"
              height="100"
              :lazy-src="$defaultProfileImage"
              :src="getProfileImage"
            >
            </v-img>
            <!-- <i class="symbol-badge bg-success"></i> -->
          </div>
          <div class="d-flex flex-column">
            <router-link
              :to="getDefaultRoute('profile.basic')"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {{ CurrentUser.full_name }}
            </router-link>
            <div
              class="text-muted mt-1 text-uppercase"
              v-if="CurrentUser.role_name"
            >
              {{ CurrentUser.role_name == 'Supervisor' ? 'engineer' : CurrentUser.role_name }}
            </div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="
                          $assetURL(
                            'media/svg/icons/Communication/Mail-notification.svg'
                          )
                        "
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{ CurrentUser.user_email }}
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sign out
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <router-link
            :to="getDefaultRoute('profile.basic')"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="
                        $assetURL('media/svg/icons/General/Notification2.svg')
                      "
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Profile</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <!--begin::Item-->
          <router-link
            :to="getDefaultRoute('profile.activity')"
            href="#"
            class="navi-item"
          >
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-danger">
                    <!--begin::Svg Icon-->
                    <inline-svg
                      :src="
                        $assetURL('media/svg/icons/Files/Selected-file.svg')
                      "
                    />
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">My Activities</div>
                <div class="text-muted">Logs and notifications</div>
              </div>
            </div>
          </router-link>
          <!--end:Item-->
          <template v-if="CurrentUser.channel == 'customer'">
            <template v-if="getPermission('user:view')">
              <router-link
                :to="
                  getDefaultRoute('user', {
                    query: {
                      status: 'active',
                    },
                  })
                "
                href="#"
                class="navi-item"
              >
                <div class="navi-link">
                  <div class="symbol symbol-40 bg-light mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-md svg-icon-success">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          :src="$assetURL('media/svg/avatars/001-boy.svg')"
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </div>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">Users</div>
                    <div class="text-muted">Manage all users</div>
                  </div>
                </div>
              </router-link>
            </template>
            <template v-if="getPermission('setting:view')">
              <router-link
                :to="getDefaultRoute('profile.setting')"
                href="#"
                class="navi-item"
              >
                <div class="navi-link">
                  <div class="symbol symbol-40 bg-light mr-3">
                    <div class="symbol-label">
                      <span class="svg-icon svg-icon-md svg-icon-success">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          :src="
                            $assetURL('media/svg/icons/Shopping/Settings.svg')
                          "
                        />
                        <!--end::Svg Icon-->
                      </span>
                    </div>
                  </div>
                  <div class="navi-text">
                    <div class="font-weight-bold">Setting</div>
                    <div class="text-muted">
                      Account settings and more
                      <span
                        class="label label-light-danger label-inline font-weight-bold"
                      >
                        update
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </template>
            <router-link
              :to="getDefaultRoute('bug.report', { query: { status: 'all' } })"
              class="navi-item"
            >
              <div class="navi-link">
                <div class="symbol symbol-40 bg-light mr-3">
                  <div class="symbol-label">
                    <span class="svg-icon svg-icon-md svg-icon-success">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/bug.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </div>
                </div>
                <div class="navi-text">
                  <div class="font-weight-bold">Bug Reports</div>
                </div>
              </div>
            </router-link>
          </template>
          <!--end:Item-->
        </div>
        <!--end::Nav-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [],
      CurrentUser: new Object(),
      userChannel: "customer",
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
    this.getUserProfile();
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "admin.login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
    getUserProfile() {
      const _this = this;
      try {
        _this.CurrentUser = JwtService.currentUser();
        if (
          _this.lodash.isEmpty(_this.CurrentUser) === false &&
          _this.lodash.isObject(_this.CurrentUser)
        ) {
          _this.userChannel = _this.lodash.toString(_this.CurrentUser.channel);
        } else {
          _this.onLogout();
        }
      } catch (error) {
        _this.logError(error);
        _this.onLogout();
      }
    },
  },
  computed: {
    getProfileImage() {
      if (
        this.lodash.isEmpty(this.CurrentUser) === false &&
        this.lodash.isEmpty(this.CurrentUser.profile_logo) === false
      ) {
        return this.CurrentUser.profile_logo.file.url;
      }
      return null;
    },
  },
};
</script>
