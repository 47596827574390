var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"origin":"top center","persistent":"","content-class":"warranty-dialog","max-width":_vm.dialogWidth},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"},[(_vm.lodash.isEmpty(_vm.warrantyDetail) === false)?[_vm._v(" Update Warranty ")]:[_vm._v(" Add Warranty ")]],2),_c('v-card-text',{staticClass:"p-8 font-size-16"},[_c('perfect-scrollbar',{staticClass:"scroll",staticStyle:{"max-height":"90vh","position":"relative"},attrs:{"options":{ suppressScrollX: true }}},[_c('v-form',{ref:"warrantyForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateWarranty.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.lodash.isEmpty(_vm.warrantyDetail))?[_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Customer")]),_c('v-autocomplete',{attrs:{"items":_vm.allCustomerList,"clearable":"","dense":"","flat":"","filled":"","label":"Display Name","item-color":"cyan","color":"cyan","solo":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"item-text":"display_name","item-value":"id","rules":[
                        _vm.validateRules.required(
                          _vm.warrantyOption.customer,
                          'Customer'
                        ) ]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Customer(s) Found.')}})],1)]},proxy:true},{key:"item",fn:function(ref){
                      var item = ref.item;
return [[_c('v-list-item-avatar',[_c('v-img',{staticClass:"margin-auto grey lighten-5 custom-grey-border",attrs:{"lazy-src":_vm.$defaultProfileImage,"src":_vm.$assetAPIURL(item.profile_logo),"aspect-ratio":"1","transition":"fade-transition"}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16"},[_vm._v(_vm._s(item.display_name))]),_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16"},[_vm._v(_vm._s(item.company_name))])],1)]]}}],null,false,1800877014),model:{value:(_vm.warrantyOption.customer),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "customer", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.customer"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Product / Service")]),_c('v-autocomplete',{attrs:{"items":_vm.allProductList,"clearable":"","dense":"","flat":"","filled":"","label":"Product / Service","item-color":"cyan","color":"cyan","solo":"","disabled":_vm.pageLoading,"loading":_vm.pageLoading,"item-text":"barcode","item-value":"id","rules":[
                        _vm.validateRules.required(
                          _vm.warrantyOption.product,
                          'Product / Service'
                        ) ]},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s('No Product(s) / Service(s) Found.')}})],1)]},proxy:true},{key:"item",fn:function(ref){
                      var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16 max-content-width"},[(item.product_type === 'goods')?[_vm._v("Product")]:(item.product_type === 'service')?[_vm._v("Service")]:_vm._e(),_vm._v("# "+_vm._s(item.barcode))],2),_c('v-list-item-title',{staticClass:"text-capitalize font-weight-500 font-size-16 max-content-width"},[_vm._v(_vm._s(item.name))])],1)]}}],null,false,2308414541),model:{value:(_vm.warrantyOption.product),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "product", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.product"}})],1)]:_vm._e(),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Start Date")]),_c('DatePicker',{attrs:{"solo":"","defaultDate":_vm.warrantDefaultStartDate,"pageLoading":_vm.pageLoading,"placeholder":'Start Date'},model:{value:(_vm.warrantyOption.start_date),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "start_date", $$v)},expression:"warrantyOption.start_date"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Serial No.")]),_c('v-text-field',{attrs:{"dense":"","filled":"","rules":[
                      _vm.validateRules.required(
                        _vm.warrantyOption.unique_id,
                        'Serial No.'
                      ) ],"color":"cyan","label":"Serial No.","solo":"","flat":""},model:{value:(_vm.warrantyOption.unique_id),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "unique_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.unique_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Description")]),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","solo":"","flat":"","color":"cyan","label":"Description","row-height":"20"},model:{value:(_vm.warrantyOption.description),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.description"}})],1)],2),_c('table',{staticClass:"width-100"},[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Duration")])])]),_vm._l((_vm.defaultItemWarranty),function(warranty,index){return _c('tr',{key:index},[_c('td',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],staticClass:"pr-2 width-100",attrs:{"dense":"","filled":"","label":"Duration","rules":[
                        _vm.validateRules.required(warranty.value, 'Duration') ],"solo":"","flat":"","color":"cyan"},model:{value:(warranty.value),callback:function ($$v) {_vm.$set(warranty, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warranty.value"}})],1),_c('td',[_c('v-select',{staticClass:"pl-2 pr-2 width-100",attrs:{"items":_vm.warrantyDurationTypes,"dense":"","filled":"","solo":"","flat":"","rules":[
                        _vm.validateRules.required(
                          warranty.field,
                          'Duration Type'
                        ) ],"label":"Duration Type","color":"cyan","item-text":"text","item-value":"value","item-color":"cyan"},model:{value:(warranty.field),callback:function ($$v) {_vm.$set(warranty, "field", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warranty.field"}})],1),_c('td',{staticStyle:{"vertical-align":"top"}},[(index > 0)?[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red lighten-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.removeWarranty(index)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus")])],1)]:[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"cyan","dark":"","fab":"","small":""},on:{"click":_vm.pushWarranty}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")])],1)]],2)])})],2)],1)],1)],1)],1),_c('v-divider',{staticClass:"my-0"}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"warrant-manage-action"},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid || _vm.pageLoading,"loading":_vm.pageLoading,"color":"cyan"},on:{"click":_vm.updateWarranty}},[_vm._v("Save ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",attrs:{"loading":_vm.pageLoading,"disabled":_vm.pageLoading},on:{"click":function($event){return _vm.$emit('close:dialog')}}},[_vm._v("Cancel ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }