<template>
  <!--begin::User Listing-->
  <ListingTemplate customClass="bug-listing">
    <template v-slot:body>
      <ListingHeader>
        <template v-slot:content>
          <v-col cols="6" class="pt-0">
            <h1 class="form-title d-flex margin-auto">
              <v-select
                :disabled="dataLoading"
                :items="statusList"
                v-model="status"
                hide-details
                item-color="cyan"
                class="pt-0 mt-0 listing-status main-listing-status-filter"
                item-text="text"
                item-value="value"
              >
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                    <v-list-item-subtitle
                      v-text="item.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-chip :color="item.color" :text-color="item.textcolor">
                      <template v-if="item.value == 'all'">{{
                        item.all_bugs
                      }}</template>
                      <template v-else>{{ item.bug_status_count }}</template>
                    </v-chip>
                  </v-list-item-action>
                </template>
              </v-select>
            </h1>
          </v-col>
          <v-col cols="6" class="pt-0 justify-flex-end d-flex margin-auto">
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-bold-button"
              :to="getDefaultRoute('create.bug.report')"
              color="cyan white--text"
            >
              <v-icon dark left>mdi-plus</v-icon> Create
            </v-btn>
            <v-menu
              max-height="400"
              max-width="250"
              offset-y
              left
              :close-on-content-click="false"
              content-class="white-background"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  color="cyan white--text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon dark>mdi-table-edit</v-icon>
                </v-btn>
              </template>
              <draggable
                tag="ul"
                v-model="defaultColDefs"
                class="draggable-group"
                handle=".draggable-drag-icon"
              >
                <template v-for="cols in defaultColDefs">
                  <li
                    class="draggable-group-item"
                    :key="cols.field"
                    v-if="!cols.checkbox"
                  >
                    <v-checkbox
                      dense
                      v-model="defaultColShow"
                      v-bind:value="cols.field"
                      :label="cols.headerName"
                      :disabled="cols.fixed || dataLoading"
                      color="cyan"
                      hide-details
                      class="mt-0 mb-0"
                    ></v-checkbox>
                    <v-icon class="draggable-drag-icon" right color="cyan"
                      >mdi-drag</v-icon
                    >
                  </li>
                </template>
              </draggable>
            </v-menu>
          </v-col>
        </template>
      </ListingHeader>
      <ListingSearchCriteria
        v-if="searchEnabled"
        pageModule="Bug"
        :basicSearchFields="['barcode', 'company_name', 'display_name']"
        :allowedSearchFields="searchableArray"
      ></ListingSearchCriteria>
      <perfect-scrollbar
        :options="{ suppressScrollX: true }"
        class="scroll custom-box-top-inner-shadow"
        style="max-height: 90vh; position: relative"
      >
        <ListingTable
          :columnCount="columnLength()"
          :dataLoading="dataLoading"
          :rowData="rowData"
        >
          <template v-slot:thead>
            <thead>
              <tr>
                <template v-for="cols in defaultColDefs">
                  <th
                    v-if="cols.visible"
                    :key="cols.id"
                    class="simple-table-th"
                    :class="{
                      'checkbox-width-limit': cols.checkbox,
                      'pointer-events-none': lodash.isEmpty(rowData),
                    }"
                  >
                    <div
                      v-if="cols.sortable"
                      class="simple-table-text sortable"
                      v-on:click="
                        !dataLoading
                          ? updateSorting({
                              field: cols.field,
                              sort: cols.sort,
                            })
                          : 'javascript:void(0)'
                      "
                    >
                      {{ cols.headerName }}
                    </div>
                    <div v-else class="simple-table-text">
                      {{ cols.headerName }}
                    </div>
                    <template v-if="cols.sort">
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'asc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-ascending</v-icon
                      >
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'desc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-descending</v-icon
                      >
                    </template>
                  </th>
                </template>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody class="custom-border-bottom">
              <template v-if="!lodash.isEmpty(rowData)">
                <tr v-for="(data, index) in rowData" :key="index" link>
                  <template v-for="cols in defaultColDefs">
                    <td
                      v-if="cols.visible"
                      :key="cols.id"
                      :class="{
                        'simple-table-td': !cols.checkbox,
                        'checkbox-width-limit': cols.checkbox,
                      }"
                      v-on:click="
                        !cols.checkbox ? rowClicked(data) : 'javascript:void(0)'
                      "
                    >
                      <template v-if="cols.field == 'title'">
                        <div class="bug-listing">
                          <p class="m-0 custom-nowrap-ellipsis">
                            {{ data.title }}
                          </p>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'status'">
                        <div class="bug-listing">
                          <CustomStatus
                            :status="data.status"
                            endpoint="bug/status"
                          ></CustomStatus>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'priority'">
                        <div class="bug-listing">
                          <Priority :priority="data.priority"></Priority>
                        </div>
                      </template>
                      <template v-else-if="cols.field == 'added_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.added_by)"
                          :data="data"
                        >
                          <template v-slot:display_name>
                            {{ data.added_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.created_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.added_at) }}
                          </template>
                        </TableActivity>
                      </template>
                      <template v-else-if="cols.field == 'updated_at'">
                        <TableActivity
                          v-if="!lodash.isEmpty(data.updated_by)"
                          :data="data"
                        >
                          <template v-slot:display_name>
                            {{ data.updated_by.display_name }}
                          </template>
                          <template v-slot:date_time>
                            {{ data.modified_at }}
                          </template>
                          <template v-slot:format_date_time>
                            {{ formatDateTime(data.updated_at) }}
                          </template>
                        </TableActivity>
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
              <tr v-else-if="!dataLoading">
                <td :colspan="columnLength()" class="py-4">
                  <p
                    class="m-0 row-not-found text-center font-weight-500 font-size-16"
                  >
                    <img
                      :src="$assetURL('media/error/empty.png')"
                      class="row-not-found-image"
                    />
                    Uhh... There are no bug at the moment.
                  </p>
                </td>
              </tr>
            </tbody>
          </template>
        </ListingTable>
      </perfect-scrollbar>
      <ListingFooter
        :dataLoading="dataLoading"
        :showingFrom="showingFrom"
        :showingTo="showingTo"
        :totalRows="totalRows"
        :currentPage="currentPage"
        :totalPages="totalPages"
      ></ListingFooter>
    </template>
  </ListingTemplate>
  <!--end::User Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import Priority from "@/view/pages/partials/Priority";
import CustomStatus from "@/view/pages/partials/CustomStatus";

export default {
  mixins: [CommonMixin, ListingMixin],
  name: "bug-list",
  data() {
    return {
      pageModule: "bug-listing",
      routeAPI: "bug",
      routeName: "bug.report",
      routeDetailName: "detail.bug.report",
      status: "all",
      statusList: [],
    };
  },
  components: {
    draggable,
    Priority,
    CustomStatus,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
  },
  mounted() {
    new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Bug Report",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.defaultColDefs = [
      {
        headerName: "Title",
        field: "title",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 1,
      },
      {
        headerName: "Status",
        field: "status",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 2,
      },
      {
        headerName: "Priority",
        field: "priority",
        sort: null,
        image: true,
        visible: true,
        fixed: false,
        sortable: false,
        order: 3,
      },
      {
        headerName: "Created Time",
        child: "created_at",
        field: "added_at",
        sort: null,
        visible: true,
        fixed: false,
        sortable: false,
        order: 4,
      },
      {
        headerName: "Last Modified Time",
        child: "modified_at",
        field: "updated_at",
        sort: null,
        visible: false,
        fixed: false,
        sortable: false,
        order: 5,
      },
    ];

    const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
      visible: true,
    });

    _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    _this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 10;
  },
};
</script>
