var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.getPermission('history:view'))?_c('div',{staticClass:"internal-history-listing"},[_c('v-container',{staticClass:"custom-bthrust-style",attrs:{"fluid":""}},[_c('v-container',[_c('v-layout',{staticClass:"justify-end"},[_c('div',{staticClass:"mr-2 my-2"},[_c('v-text-field',{attrs:{"clearable":"","dense":"","filled":"","flat":"","label":"Search","hide-details":"","solo":"","disabled":_vm.pageLoading,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('search')},"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getHistories.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab")){ return null; }return _vm.getHistories.apply(null, arguments)}]},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filter.search"}})],1),_c('div',{staticClass:"mx-2 my-2"},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","flat":"","label":"Date Range","hide-details":"","solo":"","disabled":_vm.pageLoading,"clearable":"","prepend-inner-icon":"mdi-calendar","readonly":"","value":_vm.formattedDate,"color":"cyan"},on:{"click:clear":function($event){return _vm.clearFilter('dates')}}},'v-text-field',attrs,false),on))]}}],null,false,3299452005),model:{value:(_vm.datePicker),callback:function ($$v) {_vm.datePicker=$$v},expression:"datePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan","range":"","disabled":_vm.pageLoading},on:{"change":_vm.getHistories},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1)],1),_c('div',{staticClass:"ml-2 my-2"},[_c('v-tooltip',{attrs:{"top":"","content-class":"custom-top-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"custom-bold-button white--text",attrs:{"color":"cyan","tile":"","disabled":_vm.pageLoading},on:{"click":_vm.getHistories}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}],null,false,818588873)},[_c('span',[_vm._v("Search")])])],1)])],1),_c('v-simple-table',{attrs:{"fixed-header":"","height":"67vh"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"simple-table-th"},[_vm._v("Description")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"200"}},[_vm._v("IP Address")]),_c('th',{staticClass:"simple-table-th",attrs:{"width":"300"}},[_vm._v("Created Time")])])]),_c('tbody',[(_vm.histories.length > 0)?_vm._l((_vm.histories),function(history,index){return _c('tr',{key:index},[_c('td',{staticClass:"simple-table-td"},[_vm._v(" "+_vm._s(history.description)+" "),_c('span',{staticClass:"text--secondary"},[(history.added_by)?[_vm._v(" by "+_vm._s(history.added_by.display_name))]:_vm._e()],2)]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"200"}},[_vm._v(" "+_vm._s(history.ip_address)+" ")]),_c('td',{staticClass:"simple-table-td",attrs:{"width":"300"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(history.created_at)?_c('v-chip',_vm._g(_vm._b({staticClass:"mx-2 my-1 custom-grey-border",attrs:{"color":"blue-grey darken-1","text-color":"white"}},'v-chip',attrs,false),on),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-clock-outline")])],1),_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_vm._v(" "+_vm._s(history.created_at)+" ")])],1):_vm._e()]}}],null,true)},[_c('p',{staticClass:"m-0 custom-nowrap-ellipsis"},[_vm._v(" "+_vm._s(_vm.formatDateTime(history.added_at))+" ")])])],1)])}):[(_vm.pageLoading)?_vm._l((5),function(row){return _c('tr',{key:row},[_c('td',[_c('v-skeleton-loader',{staticClass:"custom-skeleton table-rows-text",attrs:{"type":"text"}})],1),_c('td',[_c('v-skeleton-loader',{staticClass:"custom-skeleton table-rows-text",attrs:{"type":"text"}})],1),_c('td',[_c('v-skeleton-loader',{staticClass:"custom-skeleton table-rows-text",attrs:{"type":"text"}})],1)])}):(!_vm.pageLoading)?_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('p',{staticClass:"m-0 row-not-found text-center font-weight-500 font-size-16"},[_c('img',{staticClass:"row-not-found-image",attrs:{"src":_vm.$assetURL('media/error/empty.png')}}),_vm._v(" Uhh... There are no history at the moment. ")])])]):_vm._e()]],2)]},proxy:true}],null,false,1061767818)}),_c('ListingFooter',{attrs:{"dataLoading":_vm.pageLoading,"currentPage":_vm.current_page,"totalPages":_vm.total_pages,"showingFrom":_vm.showingFrom,"showingTo":_vm.showingTo,"totalRows":_vm.total_rows}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }